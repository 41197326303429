import React from 'react';
import PasswordSettings from './PasswordSettings';
import TwoFactorSettings from './TwoFactorSettings';
import SessionSettings from './SessionSettings';

export default function SecuritySettings() {
  return (
    <div className="p-6">
      <h3 className="text-lg font-bold text-gray-800 mb-6">إعدادات الأمان</h3>
      <div className="space-y-6">
        <PasswordSettings />
        <TwoFactorSettings />
        <SessionSettings />
      </div>
    </div>
  );
}