import React, { useState } from 'react';
import { Download, Upload, Calendar, Clock, RefreshCw } from 'lucide-react';

interface BackupSchedule {
  frequency: 'daily' | 'weekly' | 'monthly';
  time: string;
  enabled: boolean;
  format: 'json' | 'csv';
  includeAttachments: boolean;
}

export default function BackupSettings() {
  const [schedule, setSchedule] = useState<BackupSchedule>({
    frequency: 'daily',
    time: '00:00',
    enabled: true,
    format: 'json',
    includeAttachments: true
  });

  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const handleScheduleChange = (key: keyof BackupSchedule, value: any) => {
    setSchedule({ ...schedule, [key]: value });
  };

  const handleExport = () => {
    setIsExporting(true);
    // Simulate export process
    setTimeout(() => {
      setIsExporting(false);
    }, 2000);
  };

  const handleImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsImporting(true);
      // Simulate import process
      setTimeout(() => {
        setIsImporting(false);
        event.target.value = '';
      }, 2000);
    }
  };

  return (
    <div className="p-6">
      <h3 className="text-lg font-bold text-gray-800 mb-6">النسخ الاحتياطي والتصدير</h3>

      <div className="space-y-6">
        {/* Automated Backup Settings */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-start justify-between mb-4">
            <div>
              <h4 className="text-lg font-medium text-gray-900">النسخ الاحتياطي التلقائي</h4>
              <p className="text-sm text-gray-500 mt-1">جدولة عمليات النسخ الاحتياطي التلقائي</p>
            </div>
            <RefreshCw className="h-5 w-5 text-gray-400" />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                تكرار النسخ الاحتياطي
              </label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={schedule.frequency}
                onChange={(e) => handleScheduleChange('frequency', e.target.value)}
              >
                <option value="daily">يومي</option>
                <option value="weekly">أسبوعي</option>
                <option value="monthly">شهري</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                وقت النسخ الاحتياطي
              </label>
              <input
                type="time"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={schedule.time}
                onChange={(e) => handleScheduleChange('time', e.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="enableBackup"
                className="h-4 w-4 text-[#c62222] focus:ring-[#c62222] border-gray-300 rounded ml-2"
                checked={schedule.enabled}
                onChange={(e) => handleScheduleChange('enabled', e.target.checked)}
              />
              <label htmlFor="enableBackup" className="text-sm text-gray-700">
                تفعيل النسخ الاحتياطي التلقائي
              </label>
            </div>
          </div>
        </div>

        {/* Manual Backup Controls */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h4 className="text-lg font-medium text-gray-900 mb-4">النسخ الاحتياطي اليدوي</h4>
          
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <button
                onClick={handleExport}
                disabled={isExporting}
                className="flex items-center px-4 py-2 bg-[#c62222] text-white rounded-lg hover:bg-[#c62222] disabled:opacity-50"
              >
                <Download className="h-4 w-4 ml-2" />
                {isExporting ? 'جاري التصدير...' : 'تصدير البيانات'}
              </button>

              <label className="flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 cursor-pointer">
                <Upload className="h-4 w-4 ml-2" />
                {isImporting ? 'جاري الاستيراد...' : 'استيراد البيانات'}
                <input
                  type="file"
                  className="hidden"
                  accept=".json,.csv"
                  onChange={handleImport}
                  disabled={isImporting}
                />
              </label>
            </div>

            <div className="flex items-center mt-4">
              <input
                type="checkbox"
                id="includeAttachments"
                className="h-4 w-4 text-[#c62222] focus:ring-[#c62222] border-gray-300 rounded ml-2"
                checked={schedule.includeAttachments}
                onChange={(e) => handleScheduleChange('includeAttachments', e.target.checked)}
              />
              <label htmlFor="includeAttachments" className="text-sm text-gray-700">
                تضمين المرفقات في النسخ الاحتياطي
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}