import React from 'react';
import { Edit2 } from 'lucide-react';

interface Status {
  id: string;
  name: string;
  color: string;
  isDefault: boolean;
}

interface StatusListProps {
  statuses: Status[];
  onUpdateStatus: (statusId: string, updates: Partial<Status>) => void;
}

export default function StatusList({ statuses, onUpdateStatus }: StatusListProps) {
  const getStatusColor = (color: string) => {
    switch (color) {
      case 'green':
        return 'bg-green-100 text-green-800';
      case 'yellow':
        return 'bg-yellow-100 text-yellow-800';
      case 'blue':
        return 'bg-[#c62222] text-white';
      case 'red':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الحالة</th>
              <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">النوع</th>
              <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الإجراءات</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {statuses.map(status => (
              <tr key={status.id}>
                <td className="px-6 py-4">
                  <span className={`px-3 py-1 rounded-full text-sm ${getStatusColor(status.color)}`}>
                    {status.name}
                  </span>
                </td>
                <td className="px-6 py-4 text-sm text-gray-900">
                  {status.isDefault ? 'افتراضي' : 'عادي'}
                </td>
                <td className="px-6 py-4">
                  <button
                    onClick={() => onUpdateStatus(status.id, { isDefault: !status.isDefault })}
                    className="text-gray-500 hover:text-gray-700"
                    disabled={status.isDefault}
                  >
                    <Edit2 className="h-4 w-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}