import React, { useState } from 'react';
import { Bell, Mail, MessageSquare } from 'lucide-react';

interface NotificationSetting {
  id: string;
  type: string;
  description: string;
  email: boolean;
  system: boolean;
}

const defaultSettings: NotificationSetting[] = [
  {
    id: '1',
    type: 'تحديثات المشروع',
    description: 'إشعارات عند تحديث حالة المشروع أو التقدم',
    email: true,
    system: true
  },
  {
    id: '2',
    type: 'الإنتاج اليومي',
    description: 'إشعارات عند إدخال أو تحديث الإنتاج اليومي',
    email: false,
    system: true
  },
  {
    id: '3',
    type: 'التقارير',
    description: 'إشعارات عند إنشاء تقارير جديدة',
    email: true,
    system: false
  }
];

export default function NotificationSettings() {
  const [settings, setSettings] = useState<NotificationSetting[]>(defaultSettings);

  const handleToggle = (id: string, type: 'email' | 'system') => {
    setSettings(settings.map(setting =>
      setting.id === id
        ? { ...setting, [type]: !setting[type] }
        : setting
    ));
  };

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-bold text-gray-800">إعدادات الإشعارات</h3>
      </div>

      <div className="space-y-6">
        {settings.map(setting => (
          <div key={setting.id} className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex items-start justify-between mb-4">
              <div>
                <h4 className="text-lg font-medium text-gray-900">{setting.type}</h4>
                <p className="text-sm text-gray-500 mt-1">{setting.description}</p>
              </div>
              <Bell className="h-5 w-5 text-gray-400" />
            </div>

            <div className="flex items-center space-x-6 space-x-reverse">
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={setting.email}
                  onChange={() => handleToggle(setting.id, 'email')}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:right-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#c62222]"></div>
                <span className="flex items-center mr-3 text-sm font-medium text-gray-900">
                  <Mail className="h-4 w-4 ml-1" />
                  بريد إلكتروني
                </span>
              </label>

              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={setting.system}
                  onChange={() => handleToggle(setting.id, 'system')}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:right-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#c62222]"></div>
                <span className="flex items-center mr-3 text-sm font-medium text-gray-900">
                  <MessageSquare className="h-4 w-4 ml-1" />
                  إشعارات النظام
                </span>
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}