import React, { useState } from 'react';
import { X, Upload, AlertCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import { ProjectItem } from '../../../types';
import { useAuthStore } from '../../../store/auth';
interface ExcelUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (items: Partial<ProjectItem>[]) => void;
  existingUnits: string[];
}

interface ColumnMapping {
  name: string;
  unit: string;
  targetQuantity: string;
  unitPrice: string;
}

export default function ExcelUploadModal({ 
  isOpen, 
  onClose, 
  onUpload,
  existingUnits 
}: ExcelUploadModalProps) {
  const { t } = useTranslation();
  const [fileData, setFileData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [mapping, setMapping] = useState<ColumnMapping>({
    name: '',
    unit: '',
    targetQuantity: '',
    unitPrice: ''
  });
  const [previewData, setPreviewData] = useState<Partial<ProjectItem>[]>([]);
  const [step, setStep] = useState<'upload' | 'mapping' | 'preview'>('upload');
  const [error, setError] = useState<string>('');
  const [newUnits, setNewUnits] = useState<string[]>([]);
  const [startRow, setStartRow] = useState<number>(1);
  const [endRow, setEndRow] = useState<number | null>(null);
  const { hasPermission } = useAuthStore();

  const cleanNumber = (value: string | number): number => {
    if (typeof value === 'number') return value;
    // Remove commas and any other non-numeric characters except decimal point
    return parseFloat(value.replace(/[^\d.-]/g, '')) || 0;
  };

  const isValidRow = (row: any): boolean => {
    const name = row[mapping.name]?.toString().trim();
    const unit = row[mapping.unit]?.toString().trim();
    const quantity = cleanNumber(row[mapping.targetQuantity]);
    const price = cleanNumber(row[mapping.unitPrice]);

    return Boolean(name && unit && !isNaN(quantity) && !isNaN(price) && quantity > 0 && price > 0);
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const data = await readExcelFile(file);
      if (data.length === 0) {
        setError('الملف فارغ');
        return;
      }

      setFileData(data);
      setHeaders(Object.keys(data[0]));
      setEndRow(data.length);
      setStep('mapping');
      setError('');
    } catch (err) {
      setError('حدث خطأ أثناء قراءة الملف');
      console.error(err);
    }
  };

  const readExcelFile = (file: File): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const data = event.target?.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { 
            header: "A",
            raw: false, // This ensures all values are strings
            defval: '' // Default value for empty cells
          });
          resolve(jsonData);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(file);
    });
  };

  const handleMappingChange = (field: keyof ColumnMapping, value: string) => {
    setMapping(prev => ({ ...prev, [field]: value }));
  };

  const isMappingComplete = (): boolean => {
    return Boolean(mapping.name && mapping.unit && mapping.targetQuantity && mapping.unitPrice);
  };

  const validateMapping = (): boolean => {
    if (!isMappingComplete()) {
      setError('الرجاء تحديد جميع الأعمدة المطلوبة');
      return false;
    }
    return true;
  };

  const handlePreviewData = () => {
    if (!validateMapping()) return;

    const validRows = fileData
      .slice(startRow - 1, endRow || fileData.length)
      .filter(isValidRow)
      .map(row => ({
        name: row[mapping.name].toString().trim(),
        unit: row[mapping.unit].toString().trim(),
        targetQuantity: cleanNumber(row[mapping.targetQuantity]),
        unitPrice: cleanNumber(row[mapping.unitPrice]),
        completedQuantity: 0
      }));

    if (validRows.length === 0) {
      setError('لم يتم العثور على بيانات صالحة في النطاق المحدد');
      return;
    }

    // Collect new units
    const uniqueUnits = new Set(validRows.map(item => item.unit));
    const newUnitsList = Array.from(uniqueUnits).filter(unit => !existingUnits.includes(unit));
    setNewUnits(newUnitsList);

    setPreviewData(validRows);
    setStep('preview');
    setError('');
  };

  const handleConfirmUpload = () => {
    onUpload(previewData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-4xl mx-4 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-6 border-b">
          <h3 className="text-xl font-bold">
            {step === 'upload' && 'رفع ملف Excel'}
            {step === 'mapping' && 'تحديد الأعمدة'}
            {step === 'preview' && 'معاينة البيانات'}
          </h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-md flex items-center text-red-700">
              <AlertCircle className="h-5 w-5 ml-2" />
              <span>{error}</span>
            </div>
          )}

          {step === 'upload' && (
            <div className="text-center">
              <div className="mt-4">
                <label className="cursor-pointer inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                  <Upload className="ml-2 h-5 w-5" />
                  اختر ملف Excel
                  <input
                    type="file"
                    className="hidden"
                    accept=".xlsx,.xls"
                    onChange={handleFileUpload}
                  />
                </label>
              </div>
            </div>
          )}

          {step === 'mapping' && (
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    عمود البند
                  </label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={mapping.name}
                    onChange={(e) => handleMappingChange('name', e.target.value)}
                  >
                    <option value="">اختر العمود</option>
                    {headers.map(header => (
                      <option key={header} value={header}>{header}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    عمود الوحدة
                  </label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={mapping.unit}
                    onChange={(e) => handleMappingChange('unit', e.target.value)}
                  >
                    <option value="">اختر العمود</option>
                    {headers.map(header => (
                      <option key={header} value={header}>{header}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    عمود الكمية المستهدفة
                  </label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={mapping.targetQuantity}
                    onChange={(e) => handleMappingChange('targetQuantity', e.target.value)}
                  >
                    <option value="">اختر العمود</option>
                    {headers.map(header => (
                      <option key={header} value={header}>{header}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    عمود السعر
                  </label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={mapping.unitPrice}
                    onChange={(e) => handleMappingChange('unitPrice', e.target.value)}
                  >
                    <option value="">اختر العمود</option>
                    {headers.map(header => (
                      <option key={header} value={header}>{header}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    بداية البيانات (رقم الصف)
                  </label>
                  <input
                    type="number"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={startRow}
                    onChange={(e) => setStartRow(Math.max(1, parseInt(e.target.value) || 1))}
                    min="1"
                    max={endRow || undefined}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    نهاية البيانات (رقم الصف)
                  </label>
                  <input
                    type="number"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    value={endRow || ''}
                    onChange={(e) => setEndRow(parseInt(e.target.value) || null)}
                    min={startRow}
                    max={fileData.length}
                  />
                </div>
              </div>

              <button
                onClick={handlePreviewData}
                disabled={!isMappingComplete()}
                className={`mt-4 w-full px-4 py-2 rounded-md ${
                  isMappingComplete()
                    ? 'bg-[#c62222] text-white hover:bg-[#c62222]'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
              >
                معاينة البيانات
              </button>
            </div>
          )}

        {step === 'preview' && (
          <div>
            {newUnits.length > 0 && (
              <div className="mb-4 p-4 bg-yellow-50 border border-yellow-200 rounded-md">
                <h4 className="font-medium text-yellow-800 mb-2">وحدات جديدة سيتم إضافتها:</h4>
                <ul className="list-disc list-inside">
                  {newUnits.map(unit => (
                    <li key={unit} className="text-yellow-700">{unit}</li>
                  ))}
                </ul>
              </div>
            )}

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500">
                      البند
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500">
                      الوحدة
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500">
                      الكمية المستهدفة
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500">
                      السعر
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {previewData.map((item, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 text-sm text-gray-900">{item.name}</td>
                      <td className="px-6 py-4 text-sm text-gray-900">{item.unit}</td>
                      <td className="px-6 py-4 text-sm text-gray-900">{item.targetQuantity}</td>
                      {hasPermission('view_financial') && (
                        <td className="px-6 py-4 text-sm text-gray-900">{item.unitPrice}</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-4 flex gap-4">
              <button
                onClick={() => setStep('mapping')}
                className="flex-1 px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
              >
                رجوع للتعديل
              </button>
              <button
                onClick={handleConfirmUpload}
                className="flex-1 px-4 py-2 bg-[#c62222] text-white rounded-md hover:bg-[#c62222]"
              >
                تأكيد الإضافة
              </button>
            </div>
          </div>
        )}
        </div>
      </div>
    </div>
  )};