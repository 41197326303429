// store/auth.ts
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User, UserRole, Permissions } from '../types';

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  permissions: Permissions;
  hasPermission: (permission: keyof Permissions) => boolean;
  login: (user: User) => void;
  logout: () => void;
  updateUser: (updates: Partial<User>) => void;
}

const defaultPermissions: Permissions = {
  view_dashboard: false,
  create_projects: false,
  edit_projects: false,
  delete_projects: false,
  add_project_items: false,
  view_financial: false,
  generate_reports: false,
  add_production: false,
  edit_production: false,
  view_production_reports: false,
  view_financial_reports: false,
  view_time_reports: false,
  manage_users: false,
  system_admin: false
};

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      isAuthenticated: false,
      user: null,
      permissions: defaultPermissions,
      
      hasPermission: (permission: keyof Permissions) => {
        const { user } = get();
        if (!user) return false;
        if (user.role === 'admin') return true;
        return user.permissions?.[permission] === true; // Using optional chaining
      },

      login: (user: User) => set({
        isAuthenticated: true,
        user,
        permissions: user.role === 'admin' 
          ? { ...defaultPermissions, system_admin: true } 
          : { ...defaultPermissions, ...(user.permissions || {}) }  // Merge with default permissions
      }),
      logout: () => set({
        isAuthenticated: false,
        user: null,
        permissions: defaultPermissions
      }),

      updateUser: (updates: Partial<User>) => set((state) => ({
        user: state.user ? { ...state.user, ...updates } : null,
        permissions: state.user && updates.permissions ? 
          updates.permissions : 
          state.permissions
      }))
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        permissions: state.permissions
      })
    }
  )
);