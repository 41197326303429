// utils/reportCalculations.ts
export function calculateProductionStats(data: any[]) {
    return {
      totalProduction: data.reduce((sum, item) => sum + item.quantity, 0),
      completionRate: data.reduce((sum, item) => sum + item.progress, 0) / data.length,
      totalValue: data.reduce((sum, item) => sum + item.value, 0)
    };
  }
  
  export function calculateTimelineStats(
    startDate: string,
    endDate: string,
    currentProgress: number
  ) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const today = new Date();
  
    const totalDays = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    const daysRemaining = Math.ceil((end.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    const expectedProgress = ((today.getTime() - start.getTime()) / (end.getTime() - start.getTime())) * 100;
  
    return {
      daysRemaining,
      isDelayed: currentProgress < expectedProgress,
      expectedProgress,
      actualProgress: currentProgress
    };
  }
  
  export function formatCurrency(value: number): string {
    return value.toLocaleString('en-US') + ' ريال';
  }
  