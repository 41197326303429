// components/users/AddUserModal.tsx
import React, { useState } from 'react';
import { X } from 'lucide-react';
import { User, UserRole, Permissions } from '../../../types';
import PermissionsForm from './PermissionsForm';

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (user: Omit<User, '_id' | 'createdAt' | 'updatedAt'>) => Promise<void>;
}

const defaultPermissions: Permissions = {
  view_dashboard: false,
  create_projects: false,
  edit_projects: false,
  delete_projects: false,
  add_project_items: false,
  view_financial: false,
  add_production: false,
  generate_reports: false,
  edit_production: false,
  view_production_reports: false,
  view_financial_reports: false,
  view_time_reports: false,
  manage_users: false,
  system_admin: false
};

const adminPermissions: Permissions = {
  view_dashboard: true,
  create_projects: true,
  edit_projects: true,
  delete_projects: true,
  add_project_items: true,
  view_financial: true,
  generate_reports: false,
  add_production: true,
  edit_production: true,
  view_production_reports: true,
  view_financial_reports: true,
  view_time_reports: true,
  manage_users: true,
  system_admin: true
};

export default function AddUserModal({ isOpen, onClose, onAdd }: AddUserModalProps) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    role: 'custom' as UserRole,
    status: 'active' as 'active' | 'inactive',
    permissions: defaultPermissions
  });

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleRoleChange = (role: UserRole) => {
    setFormData({
      ...formData,
      role,
      permissions: role === 'admin' ? adminPermissions : formData.permissions
    });
  };

  const handlePermissionsChange = (newPermissions: Permissions) => {
    setFormData({
      ...formData,
      permissions: newPermissions
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await onAdd(formData);
      setFormData({
        name: '',
        email: '',
        password: '',
        role: 'custom',
        status: 'active',
        permissions: defaultPermissions
      });
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'حدث خطأ أثناء إضافة المستخدم');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-4xl mx-4 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-6 border-b sticky top-0 bg-white">
          <h3 className="text-xl font-bold">إضافة مستخدم جديد</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          {error && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-700 rounded">
              {error}
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                الاسم
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
                minLength={3}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                البريد الإلكتروني
              </label>
              <input
                type="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                كلمة المرور
              </label>
              <input
                type="password"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.password}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                required
                minLength={6}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                نوع المستخدم
              </label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.role}
                onChange={(e) => handleRoleChange(e.target.value as UserRole)}
              >
                <option value="admin">مدير النظام</option>
                <option value="custom">تخصيص الصلاحيات</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                الحالة
              </label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value as 'active' | 'inactive' })}
              >
                <option value="active">نشط</option>
                <option value="inactive">غير نشط</option>
              </select>
            </div>
          </div>

          <div className="border-t pt-6">
            <PermissionsForm 
              permissions={formData.permissions}
              onChange={handlePermissionsChange}
              isSystemAdmin={formData.role === 'admin'}
            />
          </div>

          <div className="mt-6 flex justify-end space-x-4 space-x-reverse border-t pt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
              disabled={loading}
            >
              إلغاء
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-[#c62222] text-white rounded-md hover:bg-[#c62222] disabled:opacity-50"
              disabled={loading}
            >
              {loading ? 'جاري الإضافة...' : 'إضافة المستخدم'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}