// frontend/src/db/api.ts
import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:5000/api',
  baseURL: 'https://erp.metlle.sa/api',
  proxy: {
    protocol: 'https',
    host: 'erp.metlle.sa',
    port: 443
  }
});

export default api;