import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Project, ProjectItem } from '../../types';
import ProjectItemsList from './ProjectItems/ProjectItemsList';
// import { useProjectsStore } from '../../store/projects';
import { useProjectsStore } from '../../store/projects';
import ExcelUploadModal from './ProjectItems/ExcelUploadModal';
import { useAuthStore } from '../../store/auth';


interface EditProjectModalProps {
  isOpen: boolean;
  project: Project;
  onClose: () => void;
}

export default function EditProjectModal({ isOpen, project, onClose }: EditProjectModalProps) {
  const { t } = useTranslation();
  const [isExcelModalOpen, setIsExcelModalOpen] = useState(false);
  const { updateProject } = useProjectsStore();
  const { hasPermission } = useAuthStore();

  const [formData, setFormData] = useState({
    name: project.name,
    location: project.location,
    owner: project.owner,
    budget: project.budget,
    workOrder: project.workOrder,
    startDate: project.startDate,
    endDate: project.endDate,
    status: project.status,
    items: project.items || []
  });

  const existingUnits = Array.from(new Set(formData.items.map(item => item.unit)));

  const handleExcelUpload = (items: Partial<ProjectItem>[]) => {
    const newItems = items.map(item => ({
      ...item,
      itemId: `new-${crypto.randomUUID()}`,
      projectId: project.projectId,
      completedQuantity: 0
    })) as ProjectItem[];

    setFormData(prev => ({
      ...prev,
      items: [...prev.items, ...newItems]
    }));
  };

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate items
    const emptyItems = formData.items.some(item => 
      !item.name || 
      !item.unit || 
      item.targetQuantity <= 0 || 
      item.unitPrice <= 0
    );

    if (emptyItems) {
      alert('الرجاء إكمال جميع حقول البنود أو حذف البنود الفارغة');
      return;
    }

    try {
      await updateProject(project.projectId, formData);
      onClose();
    } catch (error) {
      console.error('Error updating project:', error);
    }
  };

  const handleAddItem = () => {
    const newItem: ProjectItem = {
      itemId: `new-${crypto.randomUUID()}`, 
      projectId: project.projectId,
      name: '',
      unit: 'متر مكعب',
      targetQuantity: 0,
      completedQuantity: 0,
      unitPrice: 0
    };
    
    setFormData(prev => ({
      ...prev,
      items: [...prev.items, newItem]
    }));
  };

  const handleUpdateItem = (itemId: string, updates: Partial<ProjectItem>) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map(item =>
        item.itemId === itemId ? { ...item, ...updates } : item
      )
    }));
  };

  const handleDeleteItem = (itemId: string) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.filter(item => item.itemId !== itemId)
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-6xl mx-4 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-6 border-b sticky top-0 bg-white">
          <h3 className="text-xl font-bold">{t('projects.editProject')}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('projects.projectName')}
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('projects.location')}
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.location}
                onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('projects.owner')}
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.owner}
                onChange={(e) => setFormData({ ...formData, owner: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('projects.workOrder')}
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.workOrder}
                onChange={(e) => setFormData({ ...formData, workOrder: e.target.value })}
                required
              />
            </div>
            
            {hasPermission('view_financial') && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('projects.budget')}
                </label>
                <input
                  type="number"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                  value={formData.budget}
                  onChange={(e) => setFormData({ ...formData, budget: Number(e.target.value) })}
                  required
                />   
            </div>
            )}


            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('projects.startDate')}
              </label>
              <input
                type="date"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.startDate}
                onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('projects.endDate')}
              </label>
              <input
                type="date"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.endDate}
                onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('common.status')}
              </label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value })}
              >
                <option value="نشط">{t('projects.active')}</option>
                <option value="متوقف">{t('projects.onHold')}</option>
                <option value="مكتمل">{t('projects.completed')}</option>
                <option value="ملغي">{t('projects.cancelled')}</option>
              </select>
            </div>
          </div>

          <ProjectItemsList
            items={formData.items}
            onAddItem={handleAddItem}
            onUpdateItem={handleUpdateItem}
            onDeleteItem={handleDeleteItem}
            onOpenExcelUpload={() => setIsExcelModalOpen(true)}
          />

          <div className="mt-6 flex justify-end space-x-4 space-x-reverse">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
            >
              {t('common.cancel')}
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-[#c62222] text-white rounded-md hover:bg-[#c62222]"
            >
              {t('common.save')}
            </button>
            <ExcelUploadModal
              isOpen={isExcelModalOpen}
              onClose={() => setIsExcelModalOpen(false)}
              onUpload={handleExcelUpload}
              existingUnits={existingUnits}
            /> 
          </div>
        </form>
      </div>
    </div>
  );
}