// ProjectSelector.tsx
import React from 'react';
import { Search } from 'lucide-react';
import { useProjectsStore } from '../../store/projects';

interface ProjectSelectorProps {
 selectedProjectId: string;
 onProjectSelect: (projectId: string) => void;
}

export default function ProjectSelector({
 selectedProjectId,
 onProjectSelect
}: ProjectSelectorProps) {
 const { projects } = useProjectsStore();

 // Group projects by status
 const activeProjects = projects.filter(p => p.status === 'نشط');
 const completedProjects = projects.filter(p => p.status === 'مكتمل');
 const onHoldProjects = projects.filter(p => p.status === 'متوقف');

 // Get project status label
 const getStatusLabel = (status: string) => {
   switch (status) {
     case 'active':
       return 'المشاريع النشطة';
     case 'completed':
       return 'المشاريع المكتملة';
     case 'onHold':
       return 'المشاريع المتوقفة';
     default:
       return '';
   }
 };

 // Render project group
 const renderProjectGroup = (projectsList: typeof projects, status: string) => {
   if (!projectsList.length) return null;
   
   return (
     <optgroup label={getStatusLabel(status)}>
       {projectsList.map(project => (
         <option key={project.projectId} value={project.projectId}>
           {project.name} | BO: {project.workOrder}
         </option>
       ))}
     </optgroup>
   );
 };

 return (
   <div className="bg-white rounded-lg shadow-sm p-6">
     <div className="relative">
       <select
         className="w-full px-4 py-3 pr-12 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#c62222] appearance-none"
         value={selectedProjectId}
         onChange={(e) => onProjectSelect(e.target.value)}
       >
         <option value="">اختر مشروعاً</option>
         {renderProjectGroup(activeProjects, 'active')}
         {renderProjectGroup(onHoldProjects, 'onHold')}
         {renderProjectGroup(completedProjects, 'completed')}
       </select>
       <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
     </div>
     
     {/* Show total projects info */}
     <div className="mt-4 text-sm text-gray-500 flex justify-between">
       <span>المشاريع النشطة: {activeProjects.length}</span>
       <span>المشاريع المكتملة: {completedProjects.length}</span>
       <span>المشاريع المتوقفة: {onHoldProjects.length}</span>
     </div>
   </div>
 );
}