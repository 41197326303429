// store/dailyProduction.ts
import { create } from 'zustand';
import api from '../db/api';

interface DailyProductionEntry {
  _id: string;
  projectId: string;
  itemId: string;
  quantity: number;
  date: string;
  createdAt: string;
  updatedAt: string;
}

interface DailyProductionState {
  entries: DailyProductionEntry[];
  loading: boolean;
  error: string | null;
  addEntries: (projectId: string, entries: { itemId: string; quantity: number }[], date: string) => Promise<void>;
  getEntriesByDate: (projectId: string, date: string) => Promise<void>;
}

export const useDailyProductionStore = create<DailyProductionState>()((set) => ({
  entries: [],
  loading: false,
  error: null,

  addEntries: async (projectId, entries, date) => {
    set({ loading: true, error: null });
    try {
      await Promise.all(
        entries.map(entry =>
          api.post('/daily-production', {
            projectId,
            itemId: entry.itemId,
            quantity: entry.quantity,
            date
          })
        )
      );
      
      // Refresh entries for the date
      const { data } = await api.get<DailyProductionEntry[]>(
        `/daily-production/${projectId}?date=${date}`
      );
      set({ entries: data, loading: false });
    } catch (err) {
      const error = err instanceof Error ? err.message : 'Failed to add production entries';
      set({ error, loading: false });
    }
  },

  getEntriesByDate: async (projectId, date) => {
    set({ loading: true, error: null });
    try {
      const { data } = await api.get<DailyProductionEntry[]>(
        `/daily-production/${projectId}?date=${date}`
      );
      set({ entries: data, loading: false });
    } catch (err) {
      const error = err instanceof Error ? err.message : 'Failed to load production entries';
      set({ error, loading: false });
    }
  }
}));