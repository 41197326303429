import React, { useState } from 'react';
import { FileText, FileSpreadsheet } from 'lucide-react';

interface ReportSetting {
  id: string;
  type: string;
  enabled: boolean;
  defaultFormat: 'pdf' | 'excel';
  frequency: 'daily' | 'weekly' | 'monthly';
}

const defaultSettings: ReportSetting[] = [
  {
    id: '1',
    type: 'تقارير الإنتاج',
    enabled: true,
    defaultFormat: 'pdf',
    frequency: 'daily'
  },
  {
    id: '2',
    type: 'التقارير المالية',
    enabled: true,
    defaultFormat: 'excel',
    frequency: 'monthly'
  },
  {
    id: '3',
    type: 'التقارير الزمنية',
    enabled: true,
    defaultFormat: 'pdf',
    frequency: 'weekly'
  }
];

export default function ReportSettings() {
  const [settings, setSettings] = useState<ReportSetting[]>(defaultSettings);

  const handleToggleEnabled = (id: string) => {
    setSettings(settings.map(setting =>
      setting.id === id ? { ...setting, enabled: !setting.enabled } : setting
    ));
  };

  const handleFormatChange = (id: string, format: 'pdf' | 'excel') => {
    setSettings(settings.map(setting =>
      setting.id === id ? { ...setting, defaultFormat: format } : setting
    ));
  };

  const handleFrequencyChange = (id: string, frequency: 'daily' | 'weekly' | 'monthly') => {
    setSettings(settings.map(setting =>
      setting.id === id ? { ...setting, frequency } : setting
    ));
  };

  return (
    <div className="p-6">
      <h3 className="text-lg font-bold text-gray-800 mb-6">إعدادات التقارير</h3>

      <div className="space-y-6">
        {settings.map(setting => (
          <div key={setting.id} className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h4 className="text-lg font-medium text-gray-900">{setting.type}</h4>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={setting.enabled}
                  onChange={() => handleToggleEnabled(setting.id)}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:right-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#c62222]"></div>
              </label>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  تنسيق التصدير الافتراضي
                </label>
                <div className="flex space-x-4 space-x-reverse">
                  <button
                    onClick={() => handleFormatChange(setting.id, 'pdf')}
                    className={`flex items-center px-4 py-2 rounded-lg border ${
                      setting.defaultFormat === 'pdf'
                        ? 'bg-[#c62222] border-[#c62222] text-white'
                        : 'border-gray-200 text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <FileText className="h-4 w-4 ml-2" />
                    PDF
                  </button>
                  <button
                    onClick={() => handleFormatChange(setting.id, 'excel')}
                    className={`flex items-center px-4 py-2 rounded-lg border ${
                      setting.defaultFormat === 'excel'
                        ? 'bg-[#c62222] border-[#c62222] text-white'
                        : 'border-gray-200 text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <FileSpreadsheet className="h-4 w-4 ml-2" />
                    Excel
                  </button>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  تكرار التقرير
                </label>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                  value={setting.frequency}
                  onChange={(e) => handleFrequencyChange(setting.id, e.target.value as 'daily' | 'weekly' | 'monthly')}
                >
                  <option value="daily">يومي</option>
                  <option value="weekly">أسبوعي</option>
                  <option value="monthly">شهري</option>
                </select>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}