import React from 'react';

interface Project {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  progress: number;
  status: string;
}

interface TimelineProjectProps {
  project: Project;
  currentDate: Date;
  view: 'month' | 'quarter';
}

export default function TimelineProject({ project, currentDate, view }: TimelineProjectProps) {
  const startDate = new Date(project.startDate);
  const endDate = new Date(project.endDate);
  const totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
  const elapsedDays = (new Date().getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
  const progress = Math.min(100, Math.max(0, (elapsedDays / totalDays) * 100));

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'نشط':
        return 'bg-green-100 text-green-800';
      case 'متوقف':
        return 'bg-yellow-100 text-yellow-800';
      case 'مكتمل':
        return 'bg-[#c62222] text-white';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('ar-SA', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };

  return (
    <div className="border rounded-lg p-4">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-medium text-gray-900">{project.name}</h3>
          <div className="flex items-center mt-1 text-sm text-gray-500">
            <span>{formatDate(startDate)}</span>
            <span className="mx-2">-</span>
            <span>{formatDate(endDate)}</span>
          </div>
        </div>
        <span className={`px-3 py-1 rounded-full text-sm ${getStatusColor(project.status)}`}>
          {project.status}
        </span>
      </div>

      <div className="relative">
        <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-full bg-[#c62222] transition-all duration-300"
            style={{ width: `${progress}%` }}
          />
        </div>
        <div className="absolute -bottom-6 left-0 right-0 flex justify-between text-xs text-gray-500">
          <span>{formatDate(startDate)}</span>
          <span>{formatDate(endDate)}</span>
        </div>
      </div>
    </div>
  );
}