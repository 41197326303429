import React from 'react';
import UserMenu from './UserMenu';
import NotificationsMenu from './NotificationsMenu';

interface HeaderProps {
  userName: string;
  userRole: string;
  notifications: Array<{
    id: string;
    title: string;
    message: string;
    time: string;
    isRead: boolean;
  }>;
  onLogout: () => void;
  onSettingsClick: () => void;
  onNotificationRead: (id: string) => void;
}

export default function Header({
  userName,
  userRole,
  notifications,
  onLogout,
  onSettingsClick,
  onNotificationRead
}: HeaderProps) {
  return (
    <header className="bg-white border-b border-gray-200 px-4 py-2">
      <div className="flex items-center justify-end space-x-4 space-x-reverse">
        <NotificationsMenu
          notifications={notifications}
          onNotificationRead={onNotificationRead}
        />
        <div className="h-6 w-px bg-gray-200" />
        <UserMenu
          // userName={userName}
          // userRole={userRole}
          // onLogout={onLogout}
          onSettingsClick={onSettingsClick}
        />
      </div>
    </header>
  );
}