// components/users/EditPermissionsModal.tsx
import React, { useState } from 'react';
import { X } from 'lucide-react';
import { User, Permissions } from '../../../types';
import PermissionsForm from './PermissionsForm';

const defaultPermissions: Permissions = {
    view_dashboard: false,
    create_projects: false,
    edit_projects: false,
    delete_projects: false,
    add_project_items: false,
    view_financial: false,
    add_production: false,
    edit_production: false,
    view_production_reports: false,
    view_financial_reports: false,
    view_time_reports: false,
    generate_reports: false,
    manage_users: false,
    system_admin: false
  };
  

interface EditPermissionsModalProps {
  isOpen: boolean;
  user: User;
  onClose: () => void;
  onSave: (userId: string, permissions: Permissions) => Promise<void>;
}

export default function EditPermissionsModal({
  isOpen,
  user,
  onClose,
  onSave
}: EditPermissionsModalProps) {
  const [permissions, setPermissions] = useState<Permissions>(user.permissions || defaultPermissions);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await onSave(user._id, permissions);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'حدث خطأ أثناء تحديث الصلاحيات');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl mx-4 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-6 border-b sticky top-0 bg-white">
          <h3 className="text-xl font-bold">تعديل صلاحيات المستخدم - {user.name}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          {error && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-700 rounded">
              {error}
            </div>
          )}

          <PermissionsForm
            permissions={permissions}
            onChange={setPermissions}
            isSystemAdmin={user.role === 'admin'}
          />

          <div className="mt-6 flex justify-end space-x-4 space-x-reverse border-t pt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
              disabled={loading}
            >
              إلغاء
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-[#c62222] text-white rounded-md hover:bg-[#c62222] disabled:opacity-50"
              disabled={loading}
            >
              {loading ? 'جاري الحفظ...' : 'حفظ التغييرات'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}