import React, { useState } from 'react';
import { X } from 'lucide-react';

interface Unit {
  name: string;
  symbol: string;
  isDefault: boolean;
}

interface AddUnitModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (unit: Unit) => void;
}

export default function AddUnitModal({ isOpen, onClose, onAdd }: AddUnitModalProps) {
  const [formData, setFormData] = useState<Unit>({
    name: '',
    symbol: '',
    isDefault: false
  });

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAdd(formData);
    setFormData({
      name: '',
      symbol: '',
      isDefault: false
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-lg mx-4">
        <div className="flex justify-between items-center p-6 border-b">
          <h3 className="text-xl font-bold">إضافة وحدة قياس جديدة</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                اسم الوحدة
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                الرمز
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
                value={formData.symbol}
                onChange={(e) => setFormData({ ...formData, symbol: e.target.value })}
                required
              />
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="isDefault"
                className="h-4 w-4 text-[#c62222] focus:ring-[#c62222] border-gray-300 rounded ml-2"
                checked={formData.isDefault}
                onChange={(e) => setFormData({ ...formData, isDefault: e.target.checked })}
              />
              <label htmlFor="isDefault" className="text-sm text-gray-700">
                تعيين كوحدة افتراضية
              </label>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-4 space-x-reverse">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
            >
              إلغاء
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-[#c62222] text-white rounded-md hover:bg-[#c62222]"
            >
              إضافة الوحدة
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}