// store/users.ts
import { create } from 'zustand';
import api from '../db/api';
import { User, UserRole } from '../types';

interface UsersState {
  users: User[];
  loading: boolean;
  error: string | null;
  loadUsers: () => Promise<void>;
  addUser: (user: Omit<User, '_id' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  updateUser: (userId: string, updates: Partial<User>) => Promise<void>;
  deleteUser: (userId: string) => Promise<void>;
}

export const useUsersStore = create<UsersState>()((set) => ({
  users: [],
  loading: false,
  error: null,

  loadUsers: async () => {
    set({ loading: true, error: null });
    try {
      const { data: users } = await api.get<User[]>('/users');
      set({ users, loading: false });
    } catch (err) {
      const error = err instanceof Error ? err.message : 'An error occurred while loading users';
      set({ error, loading: false });
    }
  },

  addUser: async (user) => {
    set({ error: null });
    try {
      const { data: newUser } = await api.post<User>('/users', user);
      set((state) => ({
        users: [...state.users, newUser]
      }));
    } catch (err) {
      const error = err instanceof Error ? err.message : 'An error occurred while adding user';
      set({ error });
      throw error;
    }
  },

  updateUser: async (userId, updates) => {
    set({ error: null });
    try {
      const { data: updatedUser } = await api.put<User>(`/users/${userId}`, updates);
      set((state) => ({
        users: state.users.map(user =>
          user._id === userId ? updatedUser : user
        )
      }));
    } catch (err) {
      const error = err instanceof Error ? err.message : 'An error occurred while updating user';
      set({ error });
      throw error;
    }
  },

  deleteUser: async (userId) => {
    set({ error: null });
    try {
      await api.delete(`/users/${userId}`);
      set((state) => ({
        users: state.users.filter(user => user._id !== userId)
      }));
    } catch (err) {
      const error = err instanceof Error ? err.message : 'An error occurred while deleting user';
      set({ error });
      throw error;
    }
  }
}));