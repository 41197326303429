import React from 'react';
import { Calendar } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Project } from '../../types';

interface ProjectSelectorProps {
  projects: Project[];
  selectedProjectId: string;
  onProjectSelect: (projectId: string) => void;
  date: string;
  onDateChange: (date: string) => void;
}

export default function ProjectSelector({
  projects,
  selectedProjectId,
  onProjectSelect,
  date,
  onDateChange
}: ProjectSelectorProps) {
  const { t } = useTranslation();

  const activeProjects = projects.filter(p => p.status === 'نشط'); 

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {t('dailyProduction.selectProject')}
        </label>
        <select
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
          value={selectedProjectId}
          onChange={(e) => onProjectSelect(e.target.value)}
        >
          <option value="">{t('dailyProduction.chooseProject')}</option>
          {activeProjects.map(project => (
            <option key={project.projectId} value={project.projectId}>
              {project.name} | BO: {project.workOrder}
            </option>
          ))}
        </select>
      </div>


    </div>
  );
}