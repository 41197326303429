import React from 'react';
import { Edit2, Trash2 } from 'lucide-react';

interface Unit {
  id: string;
  name: string;
  symbol: string;
  isDefault: boolean;
}

interface UnitsListProps {
  units: Unit[];
  onUpdateUnit: (unitId: string, updates: Partial<Unit>) => void;
  onDeleteUnit: (unitId: string) => void;
}

export default function UnitsList({ units, onUpdateUnit, onDeleteUnit }: UnitsListProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الوحدة</th>
              <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الرمز</th>
              <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الحالة</th>
              <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الإجراءات</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {units.map(unit => (
              <tr key={unit.id}>
                <td className="px-6 py-4 text-sm text-gray-900">{unit.name}</td>
                <td className="px-6 py-4 text-sm text-gray-900">{unit.symbol}</td>
                <td className="px-6 py-4">
                  <span className={`px-3 py-1 rounded-full text-sm ${
                    unit.isDefault
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {unit.isDefault ? 'افتراضي' : 'عادي'}
                  </span>
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center space-x-4 space-x-reverse">
                    <button
                      onClick={() => onUpdateUnit(unit.id, { isDefault: !unit.isDefault })}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                    {!unit.isDefault && (
                      <button
                        onClick={() => onDeleteUnit(unit.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}