import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import UnitsList from './UnitsList';
import StatusList from './StatusList';
import AddUnitModal from './AddUnitModal';

interface Unit {
  id: string;
  name: string;
  symbol: string;
  isDefault: boolean;
}

interface Status {
  id: string;
  name: string;
  color: string;
  isDefault: boolean;
}

const defaultUnits: Unit[] = [
  { id: '1', name: 'متر مكعب', symbol: 'm³', isDefault: true },
  { id: '2', name: 'متر مربع', symbol: 'm²', isDefault: false },
  { id: '3', name: 'متر طولي', symbol: 'm', isDefault: false },
  { id: '4', name: 'طن', symbol: 'ton', isDefault: false }
];

const defaultStatuses: Status[] = [
  { id: '1', name: 'نشط', color: 'green', isDefault: true },
  { id: '2', name: 'متوقف', color: 'yellow', isDefault: false },
  { id: '3', name: 'مكتمل', color: 'blue', isDefault: false },
  { id: '4', name: 'ملغي', color: 'red', isDefault: false }
];

export default function ProjectSettings() {
  const [units, setUnits] = useState<Unit[]>(defaultUnits);
  const [statuses, setStatuses] = useState<Status[]>(defaultStatuses);
  const [isAddUnitModalOpen, setIsAddUnitModalOpen] = useState(false);

  const handleAddUnit = (unit: Omit<Unit, 'id'>) => {
    const newUnit: Unit = {
      ...unit,
      id: Date.now().toString()
    };
    setUnits([...units, newUnit]);
    setIsAddUnitModalOpen(false);
  };

  const handleUpdateUnit = (unitId: string, updates: Partial<Unit>) => {
    setUnits(units.map(unit => 
      unit.id === unitId ? { ...unit, ...updates } : unit
    ));
  };

  const handleDeleteUnit = (unitId: string) => {
    if (window.confirm('هل أنت متأكد من حذف هذه الوحدة؟')) {
      setUnits(units.filter(unit => unit.id !== unitId));
    }
  };

  const handleUpdateStatus = (statusId: string, updates: Partial<Status>) => {
    setStatuses(statuses.map(status => 
      status.id === statusId ? { ...status, ...updates } : status
    ));
  };

  return (
    <div className="p-6">
      <div className="mb-8">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-bold text-gray-800">وحدات القياس</h3>
          <button
            onClick={() => setIsAddUnitModalOpen(true)}
            className="bg-[#c62222] text-white px-4 py-2 rounded-lg hover:bg-[#c62222] transition-colors flex items-center"
          >
            <Plus className="ml-2 h-4 w-4" />
            إضافة وحدة
          </button>
        </div>

        <UnitsList
          units={units}
          onUpdateUnit={handleUpdateUnit}
          onDeleteUnit={handleDeleteUnit}
        />
      </div>

      <div className="mb-8">
        <h3 className="text-lg font-bold text-gray-800 mb-6">حالات المشروع</h3>
        <StatusList
          statuses={statuses}
          onUpdateStatus={handleUpdateStatus}
        />
      </div>

      <AddUnitModal
        isOpen={isAddUnitModalOpen}
        onClose={() => setIsAddUnitModalOpen(false)}
        onAdd={handleAddUnit}
      />
    </div>
  );
}