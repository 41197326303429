import React from 'react';
import { Globe, Check } from 'lucide-react';
import { useSettingsStore } from '../../../store/settings';

interface DateFormat {
  id: 'hijri' | 'gregorian';
  label: string;
  format: string;
}

const dateFormats: DateFormat[] = [
  { id: 'hijri', label: 'التقويم الهجري', format: 'islamic' },
  { id: 'gregorian', label: 'التقويم الميلادي', format: 'gregorian' }
];

export default function LocalizationSettings() {
  const { dateFormat, timeZone, setDateFormat, setTimeZone } = useSettingsStore();

  const handleSaveChanges = () => {
    // Additional save logic if needed
  };

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-bold text-gray-800">التاريخ والوقت</h3>
        <Globe className="h-5 w-5 text-gray-400" />
      </div>

      <div className="space-y-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h4 className="text-lg font-medium text-gray-900 mb-4">تنسيق التاريخ</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {dateFormats.map((format) => (
              <button
                key={format.id}
                onClick={() => setDateFormat(format.id)}
                className={`flex items-center justify-between p-4 rounded-lg border ${
                  dateFormat === format.id
                    ? 'border-[#c62222] bg-[#c62222] text-white'
                    : 'border-gray-200 hover:bg-gray-50'
                }`}
              >
                <span className="font-medium">{format.label}</span>
                {dateFormat === format.id && (
                  <Check className="h-5 w-5 text-[#c62222]" />
                )}
              </button>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h4 className="text-lg font-medium text-gray-900 mb-4">المنطقة الزمنية</h4>
          <select
            value={timeZone}
            onChange={(e) => setTimeZone(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#c62222]"
          >
            <option value="Asia/Riyadh">الرياض (GMT+3)</option>
            <option value="Asia/Jeddah">جدة (GMT+3)</option>
            <option value="Asia/Dubai">دبي (GMT+4)</option>
            <option value="Asia/Kuwait">الكويت (GMT+3)</option>
          </select>
        </div>

        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleSaveChanges}
            className="px-6 py-2 bg-[#c62222] text-white rounded-md hover:bg-[#c62222]"
          >
            حفظ التغييرات
          </button>
        </div>
      </div>
    </div>
  );
}