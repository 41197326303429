// components/users/UserManagement.tsx
import React, { useState, useEffect } from 'react';
import { Plus, Search } from 'lucide-react';
import UserList from './UserList';
import AddUserModal from './AddUserModal';
import EditPermissionsModal from './EditPermissionsModal';
import { useUsersStore } from '../../../store/users';
import { useAuthStore } from '../../../store/auth';
import { User } from '../../../types';

export default function UserManagement() {
  const { users, loading, error, loadUsers, addUser, updateUser, deleteUser } = useUsersStore();
  const { hasPermission } = useAuthStore();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingUserId, setEditingUserId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const handleAddUser = async (userData: Omit<User, '_id' | 'createdAt' | 'updatedAt'>) => {
    try {
      await addUser(userData);
      setIsAddModalOpen(false);
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  const handleUpdateUser = async (userId: string, updates: Partial<User>) => {
    try {
      await updateUser(userId, updates);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleDeleteUser = async (userId: string) => {
    if (window.confirm('هل أنت متأكد من حذف هذا المستخدم؟')) {
      try {
        await deleteUser(userId);
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  const handleEditPermissions = (userId: string) => {
    setEditingUserId(userId);
  };

  const handleUpdatePermissions = async (userId: string, permissions: User['permissions']) => {
    try {
      await updateUser(userId, { permissions });
      setEditingUserId(null);
    } catch (error) {
      console.error('Error updating permissions:', error);
    }
  };

  const filteredUsers = users.filter(user => 
    user.name.toLowerCase().includes(searchQuery.toLowerCase()) || 
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (!hasPermission('manage_users')) {
    return (
      <div className="p-6 text-center text-gray-500">
        ليس لديك صلاحية الوصول لهذه الصفحة
      </div>
    );
  }

  if (loading) {
    return <div className="p-6 text-center">جاري التحميل...</div>;
  }

  if (error) {
    return <div className="p-6 text-center text-red-500">{error}</div>;
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-bold text-gray-800">إدارة المستخدمين</h3>
        <button
          onClick={() => setIsAddModalOpen(true)}
          className="bg-[#c62222] text-white px-4 py-2 rounded-lg hover:bg-[#c62222] transition-colors flex items-center"
        >
          <Plus className="ml-2 h-4 w-4" />
          إضافة مستخدم
        </button>
      </div>

      <div className="mb-6 relative">
        <input
          type="text"
          placeholder="البحث عن مستخدم..."
          className="w-full px-4 py-2 pr-12 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#c62222]"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
      </div>

      <UserList
        users={filteredUsers}
        onUpdateUser={handleUpdateUser}
        onDeleteUser={handleDeleteUser}
        onEditPermissions={handleEditPermissions}
      />

      <AddUserModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAdd={handleAddUser}
      />

      {editingUserId && (
        <EditPermissionsModal
          isOpen={true}
          user={users.find(u => u._id === editingUserId)!}
          onClose={() => setEditingUserId(null)}
          onSave={handleUpdatePermissions}
        />
      )}
    </div>
  );
}