export const ar = {
  common: {
    dashboard: 'لوحة التحكم',
    projects: 'المشاريع',
    dailyProduction: 'الإنتاج اليومي',
    timeline: 'الجدول الزمني',
    reports: 'التقارير',
    settings: 'الإعدادات',
    save: 'حفظ',
    saving: 'جاري الحفظ',
    currency: 'ريال',
    cancel: 'إلغاء',
    delete: 'حذف',
    edit: 'تعديل',
    confirm: 'تأكيد',
    date: 'التاريخ',
    status: 'الحالة',
    actions: 'الإجراءات',
    search: 'بحث',
    noData: 'لا توجد بيانات',
    loading: 'جاري التحميل...',
    accountSettings: 'إعدادات الحساب',
    logout: 'تسجيل الخروج',
    notifications: 'الإشعارات',
    noNotifications: 'لا توجد إشعارات',
    add: 'اضافة',
  },
  roles: {
    admin: 'مدير النظام',
    manager: 'مدير مشروع',
    viewer: 'مشاهد'
  },
  projects: {
    addProject: 'إضافة مشروع',
    editProject: 'تعديل مشروع',
    deleteProject: 'حذف مشروع',
    projectName: 'اسم المشروع',
    location: 'الموقع',
    owner: 'المالك',
    budget: 'الميزانية',
    workOrder: 'امر العمل',
    startDate: 'تاريخ البداية',
    endDate: 'تاريخ النهاية',
    progress: 'نسبة الإنجاز',
    active: 'نشط',
    completed: 'مكتمل',
    onHold: 'متوقف',
    cancelled: 'ملغي',
    confirmDelete: 'هل أنت متأكد من حذف هذا المشروع؟'
  },
  dashboard: {
    activeProjects: 'المشاريع النشطة',
    workingTeams: 'الفرق العاملة',
    workHours: 'ساعات العمل',
    completionRate: 'نسبة الإنجاز',
    currentProjects: 'المشاريع الحالية'
  },
  dailyProduction: {
    title: 'ادخال الانتاج اليومي',
    selectProject: 'اختر المشروع',
    chooseProject: 'اختر مشروعاً',
    quantity: 'الكمية',
    unit: 'الوحدة',
    unitPrice: 'سعر الوحدة',
    totalValue: 'القيمة الإجمالية',
    remainingQuantity: 'الكمية المتبقية',
    completedQuantity: 'الكمية المنجزة'
  },
  reports: {
    productionReportButton: 'تقارير الانتاج',
    financialReportButton: 'التقارير المالية',
    timelineReportButton: 'التقارير الزمنية',
    selectProject: 'اختر مشروع',
    productionReports: 'تقارير الإنتاج',
    financialReports: 'التقارير المالية',
    timelineReports: 'التقارير الزمنية',
    exportExcel: 'تصدير Excel',
    exportPDF: 'تصدير PDF',
    period: 'الفترة',
    daily: 'يومي',
    weekly: 'أسبوعي',
    monthly: 'شهري',
    productionReport: 'Production Report',
    financialReport: 'Financial Report',
    timelineReport: 'Timeline Report',
  },
  settings: {
    dateFormat: 'تنسيق التاريخ',
    timeZone: 'المنطقة الزمنية',
    hijriCalendar: 'التقويم الهجري',
    gregorianCalendar: 'التقويم الميلادي',
    saveChanges: 'حفظ التغييرات'
  }
};