import React, { useState, useEffect } from 'react';
import { Plus, Upload, Trash2, ChevronDown } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ProjectItem } from '../../../types';
import { useAuthStore } from '../../../store/auth';

interface ProjectItemsListProps {
  items: ProjectItem[];
  onAddItem: () => void;
  onUpdateItem: (itemId: string, updates: Partial<ProjectItem>) => void;
  onDeleteItem: (itemId: string) => void;
  onOpenExcelUpload: () => void;
}

const commonUnits = [
  'متر مكعب',
  'متر مربع',
  'متر طولي',
  'طن',
  'قطعة',
  'يوم عمل'
];

export default function ProjectItemsList({
  items,
  onAddItem,
  onUpdateItem,
  onDeleteItem,
  onOpenExcelUpload
}: ProjectItemsListProps) {
  const { t } = useTranslation();
  const [showUnitDropdown, setShowUnitDropdown] = useState<string | null>(null);
  const { hasPermission } = useAuthStore();
  const canViewFinancials = () => {
    return hasPermission('view_financial') || hasPermission('add_project_items') || hasPermission('system_admin');
  };

  const calculateTotalValue = (item: ProjectItem) => {
    return item.targetQuantity * item.unitPrice;
  };

  const calculateProgress = (item: ProjectItem) => {
    if (item.targetQuantity === 0) return 0;
    return (item.completedQuantity / item.targetQuantity) * 100;
  };

  const handleAddClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onAddItem();
  };

  // Handle clicking outside the dropdown
  useEffect(() => {
    const handleClickOutside = () => setShowUnitDropdown(null);
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className="mt-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium text-gray-900">بنود المشروع</h3>

          {hasPermission('add_project_items') && (
            <div className="flex gap-2">
                          <button
                          type="button"
                          onClick={() => onOpenExcelUpload()}
                          className="flex items-center px-4 py-2 text-sm bg-green-600 text-white rounded-lg hover:bg-green-700"
                        >
                          <Upload className="h-4 w-4 ml-2" />
                          رفع ملف Excel
                        </button>
                        <button
                          type="button"
                          onClick={handleAddClick}
                          className="flex items-center px-4 py-2 text-sm bg-[#c62222] text-white rounded-lg hover:bg-[#c62222]"
                        >
                          <Plus className="h-4 w-4 ml-2" />
                          إضافة بند
                        </button>
              </div>
        )}
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-500">البند</th>
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-500">الوحدة</th>
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-500">الكمية المستهدفة</th>
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-500">الكمية المنجزة</th>
              {canViewFinancials() && (
                <th className="px-4 py-3 text-right text-sm font-medium text-gray-500">سعر الوحدة</th>
              )}
              {canViewFinancials() && (
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-500">القيمة الإجمالية</th>
              )}
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-500">نسبة الإنجاز</th>
              <th className="px-4 py-3 text-right text-sm font-medium text-gray-500">الإجراءات</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {items.map((item) => (
              <tr key={item.itemId}>
                <td className="px-4 py-3">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border rounded-md focus:ring-2 focus:ring-[#c62222]"
                    value={item.name}
                    onChange={hasPermission('add_project_items') ?  (e) => onUpdateItem(item.itemId, { name: e.target.value }) : undefined}
                  />
                </td>
                <td className="px-4 py-3">
                  <div className="relative">
                    <div className="flex">

                      <button
                        type="button"
                        className="px-2 border border-r border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100"
                        onClick={hasPermission('add_project_items') ? (e) => {
                          
                          e.stopPropagation();
                          setShowUnitDropdown(showUnitDropdown === item.itemId ? null : item.itemId);
                        }: undefined}
                      >
                        <ChevronDown className="h-4 w-4 text-gray-500" />
                      </button>

                      <input
                        type="text"
                        className="w-full px-2 py-1 border rounded-l-md focus:ring-2 focus:ring-[#c62222]"
                        value={item.unit}
                        onChange={hasPermission('add_project_items') ? (e) => onUpdateItem(item.itemId, { unit: e.target.value }) : undefined}
                      />
                    </div>
                    
                    {showUnitDropdown === item.itemId && (
                      <div className="absolute z-10 mt-1 w-full bg-white border rounded-md shadow-lg max-h-48 overflow-y-auto">
                        {commonUnits.map((unit) => (
                          <button
                            key={unit}
                            type="button"
                            className="w-full px-4 py-2 text-right text-sm hover:bg-gray-100"
                            onClick={hasPermission('add_project_items') ? (e) => {
                              e.stopPropagation();
                              onUpdateItem(item.itemId, { unit });
                              setShowUnitDropdown(null);
                            } : undefined}
                          >
                            {unit}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <input
                    type="number"
                    className="w-full px-2 py-1 border rounded-md focus:ring-2 focus:ring-[#c62222]"
                    value={item.targetQuantity}
                    onChange={hasPermission('add_project_items') ? (e) => onUpdateItem(item.itemId, { targetQuantity: parseFloat(e.target.value) || 0 }) : undefined}
                    min="0"
                    step="any"
                  />
                </td>
                <td className="px-4 py-3">
                  <input
                    type="number"
                    className="w-full px-2 py-1 border rounded-md focus:ring-2 focus:ring-[#c62222]"
                    value={item.completedQuantity}
                    onChange={hasPermission('add_project_items') ? (e) => onUpdateItem(item.itemId, { completedQuantity: parseFloat(e.target.value) || 0 }): undefined}
                    min="0"
                    step="any"
                  />
                </td>

                {canViewFinancials() && (
                  <td className="px-4 py-3">
                    <input
                      type="number"
                      className="w-full px-2 py-1 border rounded-md focus:ring-2 focus:ring-[#c62222]"
                      value={item.unitPrice}
                      onChange={hasPermission('add_project_items') ? (e) => onUpdateItem(item.itemId, { unitPrice: parseFloat(e.target.value) || 0  }): undefined}
                      min="0"
                      step="any"
                    />
                  </td>
                )}
                {canViewFinancials() && (
                  <td className="px-4 py-3 text-gray-900">
                    {calculateTotalValue(item).toLocaleString() + ' ريال'}
                  </td>
                )}

                <td className="px-4 py-3">
                  <div className="flex items-center">
                    <div className="w-24 bg-gray-200 rounded-full h-2 ml-2">
                      <div
                        className="bg-[#c62222] rounded-full h-2"
                        style={{ width: `${calculateProgress(item)}%` }}
                      />
                    </div>
                    <span className="text-sm text-gray-600">
                      {(calculateProgress(item) || 0).toFixed(0)}%
                    </span>
                  </div>
                </td>
                <td className="px-4 py-3">

                  {hasPermission('add_project_items') && (
                      <button
                      type="button"
                      onClick={hasPermission('add_project_items') ? () => onDeleteItem(item.itemId): undefined}

                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}