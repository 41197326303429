// store/reports.ts
import { create } from 'zustand';
import api from '../db/api';
import { 
  ReportPeriod, 
  ReportDateRange,
  ProductionReport,
  FinancialReport,
  TimelineReport
} from '../types';

interface ReportsState {
  loading: boolean;
  error: string | null;
  productionReport: ProductionReport | null;
  financialReport: FinancialReport | null;
  timelineReport: TimelineReport | null;
  getProductionReport: (projectId: string, period: ReportPeriod, dateRange: ReportDateRange) => Promise<void>;
  getFinancialReport: (projectId: string, period: ReportPeriod, dateRange: ReportDateRange) => Promise<void>;
  getTimelineReport: (projectId: string, period: ReportPeriod) => Promise<void>;
}


export const useReportsStore = create<ReportsState>((set) => ({
  loading: false,
  error: null,
  productionReport: null,  // Initialize as null
  financialReport: null,   // Initialize as null
  timelineReport: null,    // Initialize as null


  getProductionReport: async (projectId, period, dateRange) => {
    try {
      set({ loading: true, error: null, productionReport: null }); 
      const response = await api.get<ProductionReport>(
        `/reports/production/${projectId}`,
        { params: { period, ...dateRange } }
      );
      set({ productionReport: response.data, loading: false });
      console.log(response.data)
    } catch (error) {
      set({ error: 'Failed to load production report', loading: false, productionReport: null });
    }
  },

  getFinancialReport: async (projectId, period, dateRange) => {
    try {
      set({ loading: true, error: null, financialReport: null });
      const response = await api.get<FinancialReport>(
        `/reports/financial/${projectId}`,
        { params: { period, ...dateRange } }
      );
      set({ financialReport: response.data, loading: false });
    } catch (error: any) {
      set({ 
        error: error.response?.data?.error || 'Failed to load financial report', 
        loading: false, 
        financialReport: null 
      });
    }
  },

  getTimelineReport: async (projectId, period) => {
    try {
      set({ loading: true, error: null, timelineReport: null });
      const response = await api.get<TimelineReport>(
        `/reports/timeline/${projectId}`,
        { params: { period } }
      );
      set({ timelineReport: response.data, loading: false });
    } catch (error: any) {
      set({ 
        error: error.response?.data?.error || 'Failed to load timeline report', 
        loading: false, 
        timelineReport: null 
      });
    }
  },

  
}));