import React, { useState } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import TimelineProject from './TimelineProject';

interface Project {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  progress: number;
  status: string;
}

const mockProjects: Project[] = [
  {
    id: '1',
    name: 'مشروع تطوير الواجهة البحرية',
    startDate: '2024-01-01',
    endDate: '2024-12-31',
    progress: 75,
    status: 'نشط'
  },
  {
    id: '2',
    name: 'إنشاء مجمع سكني',
    startDate: '2024-03-01',
    endDate: '2024-10-31',
    progress: 45,
    status: 'نشط'
  }
];

export default function Timeline() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState<'month' | 'quarter'>('month');

  const changeMonth = (direction: 'next' | 'prev') => {
    const newDate = new Date(currentDate);
    if (direction === 'next') {
      newDate.setMonth(newDate.getMonth() + 1);
    } else {
      newDate.setMonth(newDate.getMonth() - 1);
    }
    setCurrentDate(newDate);
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">الجدول الزمني</h2>
        <div className="flex items-center space-x-4 space-x-reverse">
          <div className="flex items-center bg-white rounded-lg shadow-sm">
            <button
              onClick={() => changeMonth('prev')}
              className="p-2 hover:bg-gray-50 rounded-r-lg"
            >
              <ChevronRight className="h-5 w-5 text-gray-600" />
            </button>
            <span className="px-4 py-2 border-x">
              {currentDate.toLocaleDateString('ar-SA', { month: 'long', year: 'numeric' })}
            </span>
            <button
              onClick={() => changeMonth('next')}
              className="p-2 hover:bg-gray-50 rounded-l-lg"
            >
              <ChevronLeft className="h-5 w-5 text-gray-600" />
            </button>
          </div>
          <select
            className="bg-white border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#c62222]"
            value={view}
            onChange={(e) => setView(e.target.value as 'month' | 'quarter')}
          >
            <option value="month">شهري</option>
            <option value="quarter">ربع سنوي</option>
          </select>
        </div>
      </div>

      <div className="space-y-4">
        {mockProjects.map(project => (
          <TimelineProject
            key={project.id}
            project={project}
            currentDate={currentDate}
            view={view}
          />
        ))}
      </div>
    </div>
  );
}