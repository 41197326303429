import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface SettingsState {
  dateFormat: 'hijri' | 'gregorian';
  timeZone: string;
  setDateFormat: (format: 'hijri' | 'gregorian') => void;
  setTimeZone: (timeZone: string) => void;
}

export const useSettingsStore = create<SettingsState>()(
  persist(
    (set) => ({
      dateFormat: 'hijri',
      timeZone: 'Asia/Riyadh',
      setDateFormat: (dateFormat) => set({ dateFormat }),
      setTimeZone: (timeZone) => set({ timeZone })
    }),
    {
      name: 'settings-storage',
      partialize: (state) => ({
        dateFormat: state.dateFormat,
        timeZone: state.timeZone
      })
    }
  )
);