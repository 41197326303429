// components/reports/FinancialReport.tsx
import React, { useEffect } from 'react';
import { ReportPeriod, FinancialReport as IFinancialReport } from '../../types';
import { useReportsStore } from '../../store/reports';
import { DollarSign, TrendingUp, ArrowUpRight } from 'lucide-react';
import { formatCurrency } from '../../utils/reportCalculations';

interface FinancialReportProps {
  projectId: string;
  period: ReportPeriod;
  dateRange: {
    startDate: string;
    endDate: string;
  };
}

export default function FinancialReport({ projectId, period, dateRange }: FinancialReportProps) {
  const { getFinancialReport, financialReport, loading, error } = useReportsStore();

  useEffect(() => {
    if (projectId && dateRange.startDate && dateRange.endDate) {
      getFinancialReport(projectId, period, dateRange);
    }
  }, [projectId, period, dateRange.startDate, dateRange.endDate]);

  if (loading) return <div className="text-center p-8">جاري تحميل البيانات...</div>;
  if (error) return <div className="text-center p-8 text-red-600">{error}</div>;
  if (!financialReport) return null;

  const formatNumber = (value: number | undefined | null): string => {
    if (value === undefined || value === null) return '0';
    return value.toLocaleString();
  };

  const formatPercent = (value: number | undefined | null): string => {
    if (value === undefined || value === null) return '0';
    return value.toFixed(1);
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600 mb-1">القيمة المحققة</p>
              <p className="text-2xl font-bold text-gray-800">
                {formatCurrency(financialReport.totalValue)}
              </p>
            </div>
            <div className="bg-[#c62222] p-3 rounded-full">
              <DollarSign className="h-6 w-6 text-white" />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600 mb-1">نسبة الإنجاز المالي</p>
              <p className="text-2xl font-bold text-gray-800">
                {formatPercent(financialReport.totalProgress)}%
              </p>
            </div>
            <div className="bg-green-100 p-3 rounded-full">
              <TrendingUp className="h-6 w-6 text-green-600" />
            </div>
          </div>
        </div>

        {/* <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600 mb-1">الميزانية المتبقية</p>
              <p className="text-2xl font-bold text-gray-800">
                {formatCurrency(financialReport.budgetVariance)}
              </p>
            </div>
            <div className="bg-blue-100 p-3 rounded-full">
              <ArrowUpRight className="h-6 w-6 text-blue-600" />
            </div>
          </div>
        </div> */}
      </div>

      {/* Financial Details */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h3 className="text-lg font-bold text-gray-800">تفاصيل الإنجاز المالي</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">البند</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الوحدة</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الكمية المنفذة في الفترة</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">سعر الوحدة</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">القيمة المحققة</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">القيمة المخططة</th>
                <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">نسبة الإنجاز</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {financialReport.data.map((item) => (
                <tr key={item.itemId}>
                  <td className="px-6 py-4 text-sm text-gray-900">{item.name}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{item.unit}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{formatNumber(item.periodQuantity)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{formatCurrency(item.unitPrice)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{formatCurrency(item.value)}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{formatCurrency(item.plannedValue)}</td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <div className="w-24 bg-gray-200 rounded-full h-2 ml-2">
                        <div
                          className="bg-[#c62222] rounded-full h-2"
                          style={{ width: `${item.progress}%` }}
                        />
                      </div>
                      <span className="text-sm text-gray-600">{formatPercent(item.progress)}%</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Budget Summary */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h3 className="text-lg font-bold text-gray-800 mb-4">ملخص الميزانية</h3>
        <div className="space-y-4">
          {/* <div className="flex items-center justify-between">
            <span className="text-gray-600">الميزانية الكلية</span>
            <span className="font-medium text-gray-800">{formatCurrency(financialReport.projectBudget)}</span>
          </div> */}
          <div className="flex items-center justify-between">
            <span className="text-gray-600">القيمة المنجزة</span>
            <span className="font-medium text-gray-800">{formatCurrency(financialReport.totalValue)}</span>
          </div>
          {/* <div className="flex items-center justify-between">
            <span className="text-gray-600">الميزانية المتبقية</span>
            <span className="font-medium text-gray-800">{formatCurrency(financialReport.budgetVariance)}</span>
          </div> */}
        </div>
      </div>
    </div>
  );
}