// components/users/UserList.tsx
import React from 'react';
import { Edit2, Trash2, Shield } from 'lucide-react';
import { User } from '../../../types';

interface UserListProps {
  users: User[];
  onUpdateUser: (_id: string, updates: Partial<User>) => void;
  onDeleteUser: (_id: string) => void;
  onEditPermissions: (_id: string) => void;
}

export default function UserList({ users, onUpdateUser, onDeleteUser, onEditPermissions }: UserListProps) {
  const getRoleLabel = (role: string) => {
    switch (role) {
      case 'admin':
        return 'مدير النظام';
      case 'custom':
        return 'مخصص';
      default:
        return role;
    }
  };

  const getPermissionsCount = (user: User) => {
    if (user.role === 'admin') return 'جميع الصلاحيات';
    
    if (!user.permissions) return '0 صلاحية';
    
    const activePermissions = Object.entries(user.permissions)
      .filter(([key, value]) => value === true && key !== 'system_admin')
      .length;
    
    return `${activePermissions} صلاحية`;
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الاسم</th>
            <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">البريد الإلكتروني</th>
            <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">نوع المستخدم</th>
            <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الصلاحيات</th>
            <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الحالة</th>
            <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">الإجراءات</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {users.map(user => (
            <tr key={user._id}>
              <td className="px-6 py-4 text-sm text-gray-900">{user.name}</td>
              <td className="px-6 py-4 text-sm text-gray-900">{user.email}</td>
              <td className="px-6 py-4 text-sm text-gray-900">{getRoleLabel(user.role)}</td>
              <td className="px-6 py-4 text-sm text-gray-900">{getPermissionsCount(user)}</td>
              <td className="px-6 py-4">
                <span className={`px-3 py-1 rounded-full text-sm ${
                  user.status === 'active'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800'
                }`}>
                  {user.status === 'active' ? 'نشط' : 'معطل'}
                </span>
              </td>
              <td className="px-6 py-4">
                <div className="flex items-center space-x-4 space-x-reverse">
                  {user.role !== 'admin' && (
                    <button
                      onClick={() => onEditPermissions(user._id)}
                      className="text-blue-500 hover:text-blue-700"
                      title="تعديل الصلاحيات"
                    >
                      <Shield className="h-4 w-4" />
                    </button>
                  )}
                  <button
                    onClick={() => onUpdateUser(user._id, {
                      status: user.status === 'active' ? 'inactive' : 'active',
                      permissions: user.permissions // Include existing permissions
                    })}
                    className="text-gray-500 hover:text-gray-700"
                    title="تعديل المستخدم"
                  >
                    <Edit2 className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => onDeleteUser(user._id)}
                    className="text-red-500 hover:text-red-700"
                    title="حذف المستخدم"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}