// components/reports/ReportFilters.tsx
import React from 'react';
import { ReportPeriod } from '../../types';
import ProjectSelector from './ProjectSelector';

interface ReportFiltersProps {
  period: ReportPeriod;
  onPeriodChange: (period: ReportPeriod) => void;
  dateRange: {
    startDate: string;
    endDate: string;
  };
  onDateRangeChange: (range: { startDate: string; endDate: string }) => void;
  selectedProjectId: string;
  onProjectSelect: (projectId: string) => void;
}

export default function ReportFilters({
  period,
  onPeriodChange,
  dateRange,
  onDateRangeChange,
  selectedProjectId,
  onProjectSelect
}: ReportFiltersProps) {
  const handlePeriodChange = (newPeriod: ReportPeriod) => {
    onPeriodChange(newPeriod);
    
    // Reset date range based on period
    const today = new Date();
    let startDate = today.toISOString().split('T')[0];
    let endDate = today.toISOString().split('T')[0];

    switch (newPeriod) {
      case 'weekly':
        const weekStart = new Date(today.setDate(today.getDate() - today.getDay()));
        startDate = weekStart.toISOString().split('T')[0];
        break;
      case 'monthly':
        const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        startDate = monthStart.toISOString().split('T')[0];
        break;
    }

    onDateRangeChange({ startDate, endDate });
  };

  return (
    <div className="space-y-4">
      <ProjectSelector
        selectedProjectId={selectedProjectId}
        onProjectSelect={onProjectSelect}
      />

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              الفترة
            </label>
            <select
              className="w-full px-4 py-2 border border-gray-300 rounded-md"
              value={period}
              onChange={(e) => handlePeriodChange(e.target.value as ReportPeriod)}
            >
              <option value="daily">يومي</option>
              <option value="weekly">أسبوعي</option>
              <option value="monthly">شهري</option>
              <option value="custom">مخصص</option>
            </select>
          </div>

          {period === 'custom' && (
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  من تاريخ
                </label>
                <input
                  type="date"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={dateRange.startDate}
                  onChange={(e) =>
                    onDateRangeChange({ ...dateRange, startDate: e.target.value })
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  إلى تاريخ
                </label>
                <input
                  type="date"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  value={dateRange.endDate}
                  onChange={(e) =>
                    onDateRangeChange({ ...dateRange, endDate: e.target.value })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}