import React from 'react';
import { Building2, Users, Clock, TrendingUp } from 'lucide-react';
import ProjectCard from './Projects/ProjectCard';
import { useTranslation } from 'react-i18next';
import { useProjectsStore } from '../store/projects';

export default function Dashboard() {
  const { t } = useTranslation();
  const { projects } = useProjectsStore();
  const activeProjects = projects.filter(p => p.status === 'نشط');

  const stats = [
    { icon: Building2, label: t('dashboard.activeProjects'), value: activeProjects.length.toString() },
    { icon: Users, label: t('dashboard.workingTeams'), value: '0' },
    { icon: Clock, label: t('dashboard.workHours'), value: '0' },
    { icon: TrendingUp, label: t('dashboard.completionRate'), value: '0%' }
  ];

  return (
    <div className="p-8">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-800">{t('common.dashboard')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
          {stats.map((stat, index) => (
            <div key={index} className="bg-white rounded-xl shadow-sm p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-600 mb-1">{stat.label}</p>
                  <p className="text-2xl font-bold text-gray-800">{stat.value}</p>
                </div>
                <stat.icon className="h-8 w-8 text-[#c62222]" />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-8">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-xl font-bold text-gray-800">{t('dashboard.currentProjects')}</h3>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {activeProjects.map((project) => (
            <ProjectCard key={project.projectId} project={project} />
          ))}
        </div>
      </div>
    </div>
  );
}