// components/users/PermissionsForm.tsx
import React from 'react';
import { Permissions } from '../../../types';

interface PermissionsFormProps {
  permissions: Permissions;
  onChange: (permissions: Permissions) => void;
  isSystemAdmin: boolean;
}

export default function PermissionsForm({ permissions, onChange, isSystemAdmin }: PermissionsFormProps) {
  const handleChange = (key: keyof Permissions) => {
    onChange({
      ...permissions,
      [key]: !permissions[key]
    });
  };

  if (isSystemAdmin) {
    return (
      <div className="bg-gray-50 p-4 rounded-md">
        <p className="text-gray-700 text-sm">مدير النظام لديه جميع الصلاحيات</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <h4 className="font-medium text-gray-900">الصلاحيات</h4>
      
      {/* Dashboard Section */}
      <div className="border rounded-md p-4">
        <h5 className="font-medium mb-3">لوحة التحكم</h5>
        <div className="space-y-2">
          <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.view_dashboard}
              onChange={() => handleChange('view_dashboard')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">اطلاع فقط</span>
          </label>
        </div>
      </div>

      {/* Projects Section */}
      <div className="border rounded-md p-4">
        <h5 className="font-medium mb-3">المشاريع</h5>
        <div className="space-y-2">
          <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.create_projects}
              onChange={() => handleChange('create_projects')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">إنشاء مشروع</span>
          </label>
          <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.edit_projects}
              onChange={() => handleChange('edit_projects')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">تعديل مشروع</span>
          </label>
          <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.delete_projects}
              onChange={() => handleChange('delete_projects')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">حذف مشروع</span>
          </label>
          <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.add_project_items}
              onChange={() => handleChange('add_project_items')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">إضافة بنود</span>
          </label>
        </div>
      </div>

      {/* Financial Section */}
      <div className="border rounded-md p-4">
        <h5 className="font-medium mb-3">الأمور المالية</h5>
        <div className="space-y-2">
          <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.view_financial}
              onChange={() => handleChange('view_financial')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">رؤية التفاصيل المالية وتعديلها في حال وجود الصلاحيات الكافية</span>
          </label>
        </div>
      </div>

      {/* Production Section */}
      <div className="border rounded-md p-4">
        <h5 className="font-medium mb-3">الإنتاج اليومي</h5>
        <div className="space-y-2">
          <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.add_production}
              onChange={() => handleChange('add_production')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">إضافة إنتاج</span>
          </label>
          <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.edit_production}
              onChange={() => handleChange('edit_production')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">تعديل إنتاج</span>
          </label>
        </div>
      </div>

      {/* Reports Section */}
      <div className="border rounded-md p-4">
        <h5 className="font-medium mb-3">التقارير</h5>
        <div className="space-y-2">
        <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.generate_reports}
              onChange={() => handleChange('generate_reports')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">انتاج تقارير</span>
          </label>
          <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.view_production_reports}
              onChange={() => handleChange('view_production_reports')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">تقارير الإنتاج</span>
          </label>
          <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.view_financial_reports}
              onChange={() => handleChange('view_financial_reports')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">تقارير مالية</span>
          </label>
          <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.view_time_reports}
              onChange={() => handleChange('view_time_reports')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">تقارير زمنية</span>
          </label>
        </div>
      </div>

      {/* User Management Section */}
      <div className="border rounded-md p-4">
        <h5 className="font-medium mb-3">إدارة المستخدمين</h5>
        <div className="space-y-2">
          <label className="flex items-center space-x-2 space-x-reverse">
            <input
              type="checkbox"
              checked={permissions.manage_users}
              onChange={() => handleChange('manage_users')}
              className="rounded border-gray-300 text-[#c62222] focus:ring-[#c62222]"
            />
            <span className="text-sm">إدارة المستخدمين والصلاحيات</span>
          </label>
        </div>
      </div>
    </div>
  );
}