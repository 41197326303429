import React, { useState } from 'react';
import { Plus, Search } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../store/auth';
import { useProjectsStore } from '../../store/projects';
import ProjectCard from './ProjectCard';
import AddProjectModal from './AddProjectModal';
import EditProjectModal from './EditProjectModal';
import { Project } from '../../types';


type NewProject = Omit<Project, 'projectId' | 'progress'>;

export default function ProjectsList() {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingProject, setEditingProject] = useState<Project | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation();
  const { hasPermission } = useAuthStore();
  const { projects, addProject, updateProject, deleteProject } = useProjectsStore();

  const filteredProjects = projects.filter(project =>
    project.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    project.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
    project.workOrder.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleAddProject = async (projectData: NewProject) => {
    try {
      await addProject(projectData);
      setIsAddModalOpen(false);
    } catch (error) {
      console.error('Error adding project:', error);
      // You might want to show an error notification here
    }
  };

  // const handleEditProject = async (projectData: Partial<Project>) => {
  //   if (editingProject) {
  //     try {
  //       await updateProject(editingProject.projectId, projectData);
  //       setEditingProject(null);
  //     } catch (error) {
  //       console.error('Error updating project:', error);
  //       // You might want to show an error notification here
  //     }
  //   }
  // };

  const handleDeleteProject = async (projectId: string) => {
    if (window.confirm(t('projects.confirmDelete'))) {
      try {
        await deleteProject(projectId);
      } catch (error) {
        console.error('Error deleting project:', error);
        // You might want to show an error notification here
      }
    }
  };

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">{t('common.projects')}</h2>
        {hasPermission('create_projects') && (
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="bg-[#c62222] text-white px-6 py-2 rounded-lg hover:bg-[#c62222] transition-colors flex items-center"
          >
            <Plus className="ml-2 h-5 w-5" />
            {t('projects.addProject')}
          </button>
        )}
      </div>

      <div className="mb-6 relative">
        <input
          type="text"
          placeholder={t('common.search')}
          className="w-full px-4 py-2 pr-12 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#c62222]"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredProjects.map(project => (
          <ProjectCard
            key={project.projectId}
            project={project}
            onEdit={hasPermission('edit_projects') ? () => setEditingProject(project) : undefined}
            onDelete={hasPermission('delete_projects') ? () => handleDeleteProject(project.projectId) : undefined}
          />
        ))}
      </div>

      <AddProjectModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAdd={handleAddProject}
      />

      {editingProject && (
        <EditProjectModal
          isOpen={!!editingProject}
          project={editingProject}
          onClose={() => setEditingProject(null)}
        />
      )}
    </div>
  );
}