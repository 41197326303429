// src/store/projects.ts
import { create } from 'zustand';
import api from '../db/api';
import { Project, ProjectItem } from '../types';

interface ProjectWithItems extends Project {
  items: ProjectItem[];
}

interface ProjectsState {
  projects: ProjectWithItems[];
  loading: boolean;
  error: string | null;
  loadProjects: () => Promise<void>;
  addProject: (project: Omit<Project, 'projectId' | 'progress' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  updateProject: (projectId: string, updates: Partial<Project> & { items: ProjectItem[] }) => Promise<void>;
  deleteProject: (projectId: string) => Promise<void>;
  addProjectItem: (projectId: string, item: Omit<ProjectItem, 'itemId' | 'projectId' | 'completedQuantity' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  updateProjectItem: (projectId: string, itemId: string, updates: Partial<ProjectItem>) => Promise<void>;
  deleteProjectItem: (projectId: string, itemId: string) => Promise<void>;
  updateProjectProgress: (projectId: string) => Promise<void>;
}

export const useProjectsStore = create<ProjectsState>()((set, get) => ({
  projects: [],
  loading: false,
  error: null,

  loadProjects: async () => {
    set({ loading: true, error: null });
    try {
      const { data: projects } = await api.get<Project[]>('/projects');
      const projectsWithItems = await Promise.all(
        projects.map(async (project) => {
          const { data: items } = await api.get<ProjectItem[]>(`/projects/${project.projectId}/items`);
          return { ...project, items };
        })
      );
      set({ projects: projectsWithItems, loading: false });
    } catch (err) {
      const error = err instanceof Error ? err.message : 'An error occurred while loading projects';
      set({ error, loading: false });
    }
  },

  addProject: async (project) => {
    set({ error: null });
    try {
      const { data: newProject } = await api.post<Project>('/projects', project);
      set((state) => ({
        projects: [...state.projects, { ...newProject, items: [] }]
      }));
    } catch (err) {
      const error = err instanceof Error ? err.message : 'An error occurred while adding project';
      set({ error });
    }
  },

  // store/projects.ts
  updateProject: async (projectId, updates: Partial<Project> & { items: ProjectItem[] }) => {
    set({ error: null });
    try {
      // Get current items to compare with updated items
      const currentProject = get().projects.find(p => p.projectId === projectId);
      const deletedItems = currentProject?.items.filter(
        existingItem => !updates.items.some(
          newItem => newItem.itemId === existingItem.itemId
        )
      );

      // First update the project details
      const { data: updatedProject } = await api.put<Project>(`/projects/${projectId}`, {
        name: updates.name,
        location: updates.location,
        owner: updates.owner,
        budget: updates.budget,
        workOrder: updates.workOrder,
        startDate: updates.startDate,
        endDate: updates.endDate,
        status: updates.status,
      });

      // Delete removed items
      if (deletedItems?.length) {
        await Promise.all(
          deletedItems.map(item => 
            api.delete(`/projects/${projectId}/items/${item.itemId}`)
          )
        );
      }

      // Handle remaining items (create new, update existing)
      if (updates.items.length) {
        await Promise.all(
          updates.items.map(item => {
            if (item.itemId.startsWith('new-')) {
              // Create new item
              return api.post(`/projects/${projectId}/items`, {
                name: item.name,
                unit: item.unit,
                targetQuantity: item.targetQuantity,
                completedQuantity: item.completedQuantity,
                unitPrice: item.unitPrice
              });
            }
            // Update existing item
            return api.put(`/projects/${projectId}/items/${item.itemId}`, {
              name: item.name,
              unit: item.unit,
              targetQuantity: item.targetQuantity,
              completedQuantity: item.completedQuantity,
              unitPrice: item.unitPrice
            });
          })
        );
      }

      // Get updated items list
      const { data: updatedItems } = await api.get<ProjectItem[]>(`/projects/${projectId}/items`);

      // Update local state
      set((state) => ({
        projects: state.projects.map(project =>
          project.projectId === projectId
            ? { ...updatedProject, items: updatedItems }
            : project
        )
      }));
    } catch (err) {
      const error = err instanceof Error ? err.message : 'An error occurred while updating project';
      set({ error });
      throw error;
    }
  },
  
  deleteProject: async (projectId) => {
    set({ error: null });
    try {
      await api.delete(`/projects/${projectId}`);
      set((state) => ({
        projects: state.projects.filter(project => project.projectId !== projectId)
      }));
    } catch (err) {
      const error = err instanceof Error ? err.message : 'An error occurred while deleting project';
      set({ error });
    }
  },

  addProjectItem: async (projectId, item) => {
    set({ error: null });
    try {
      const { data: newItem } = await api.post<ProjectItem>(`/projects/${projectId}/items`, item);
      
      set((state) => ({
        projects: state.projects.map(project =>
          project.projectId === projectId
            ? {
                ...project,
                items: [...(project.items || []), newItem]
              }
            : project
        )
      }));
      
      await get().updateProjectProgress(projectId);
    } catch (err) {
      const error = err instanceof Error ? err.message : 'An error occurred while adding project item';
      set({ error });
    }
  },

  updateProjectItem: async (projectId, itemId, updates) => {
    set({ error: null });
    try {
      const { data: updatedItem } = await api.put<ProjectItem>(
        `/projects/${projectId}/items/${itemId}`, 
        updates
      );
      
      set((state) => ({
        projects: state.projects.map(project =>
          project.projectId === projectId
            ? {
                ...project,
                items: project.items?.map(item =>
                  item.itemId === itemId ? updatedItem : item
                )
              }
            : project
        )
      }));
      
      await get().updateProjectProgress(projectId);
    } catch (err) {
      const error = err instanceof Error ? err.message : 'An error occurred while updating project item';
      set({ error });
    }
  },

  deleteProjectItem: async (projectId, itemId) => {
    set({ error: null });
    try {
      await api.delete(`/projects/${projectId}/items/${itemId}`);
      set((state) => ({
        projects: state.projects.map(project =>
          project.projectId === projectId
            ? {
                ...project,
                items: project.items?.filter(item => item.itemId !== itemId)
              }
            : project
        )
      }));
      
      await get().updateProjectProgress(projectId);
    } catch (err) {
      const error = err instanceof Error ? err.message : 'An error occurred while deleting project item';
      set({ error });
    }
  },

  updateProjectProgress: async (projectId) => {
    set({ error: null });
    try {
      const project = get().projects.find(p => p.projectId === projectId);
      if (!project?.items?.length) return;

      const totalProgress = project.items.reduce((acc, item) => {
        const itemProgress = (item.completedQuantity / item.targetQuantity) * 100;
        return acc + itemProgress;
      }, 0) / project.items.length;

      const roundedProgress = Math.round(totalProgress);
      
      const { data: updatedProject } = await api.put<Project>(
        `/projects/${projectId}`, 
        { progress: roundedProgress }
      );
      
      set((state) => ({
        projects: state.projects.map(p =>
          p.projectId === projectId ? { ...p, progress: roundedProgress } : p
        )
      }));
    } catch (err) {
      const error = err instanceof Error ? err.message : 'An error occurred while updating project progress';
      set({ error });
    }
  }
}));