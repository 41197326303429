import React, { useState } from 'react';
import { LayoutDashboard, ClipboardList, Calendar, BarChart3, Settings, Layers, Menu, X } from 'lucide-react';
import { Section,Permissions } from '../types';
import { useAuthStore } from '../store/auth';

interface MenuItem {
  id: Section;
  icon: React.FC<{ className?: string }>;
  label: string;
  requiredPermission: keyof Permissions | (keyof Permissions)[];
}


const menuItems: { id: Section; icon: React.FC<{ className?: string }>; label: string; requiredPermission: keyof Permissions | (keyof Permissions)[]}[] = [
  { 
    id: 'dashboard', 
    icon: LayoutDashboard, 
    label: 'لوحة التحكم',
    requiredPermission: ['view_dashboard', 'view_financial']
  },
  { 
    id: 'projects', 
    icon: ClipboardList, 
    label: 'المشاريع',
    requiredPermission: 'create_projects'
  },
  { 
    id: 'production', 
    icon: Layers, 
    label: 'الإنتاج اليومي',
    requiredPermission: 'add_production'
  },
  { 
    id: 'reports', 
    icon: BarChart3, 
    label: 'التقارير',
    requiredPermission: ['view_production_reports', 'view_financial_reports', 'view_time_reports', 'generate_reports'] 
  },   
  { 
    id: 'settings', 
    icon: Settings, 
    label: 'الإعدادات',
    requiredPermission: 'manage_users'
  }
];


interface SidebarProps {
  currentSection: Section;
  onSectionChange: (section: Section) => void;
}

export default function Sidebar({ currentSection, onSectionChange }: SidebarProps) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { hasPermission } = useAuthStore();

  const handleMenuItemClick = (section: Section) => {
    onSectionChange(section);
    setIsMobileMenuOpen(false);
  };

  const authorizedMenuItems = menuItems.filter(item => {
    if (Array.isArray(item.requiredPermission)) {
      return item.requiredPermission.some(perm => hasPermission(perm));
    }
    return hasPermission(item.requiredPermission);
  });

  
  return (
    <>
      {/* Mobile Menu Button */}
      {authorizedMenuItems.length > 0 && (
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="lg:hidden fixed top-4 right-4 z-50 p-2 rounded-lg bg-white shadow-md text-gray-700 hover:bg-gray-100"
        >
          {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
        </button>
      )}

      {/* Overlay for mobile */}
      {isMobileMenuOpen && (
        <div 
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}

      {/* Sidebar */}
      <aside className={`
        fixed top-0 right-0 h-full z-40 bg-white border-l border-gray-200 p-4
        transform transition-transform duration-300 ease-in-out
        lg:translate-x-0 lg:static lg:w-64
        ${isMobileMenuOpen ? 'translate-x-0 w-64' : 'translate-x-full'}
      `}>
        {/* Logo Container */}
        <div className="mb-8 flex items-center justify-center">
          <img 
            src="/logo.png"
            alt="Company Logo" 
            className="h-24 w-auto"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null;
              target.style.display = 'none';
              target.parentElement!.innerHTML = `<div class="text-xl font-bold text-gray-800">LOGO</div>`;
            }}
          />
        </div>

        {/* Navigation Menu */}
        <nav className="space-y-1">
          {authorizedMenuItems.map((item) => (
            <button
              key={item.id}
              onClick={() => handleMenuItemClick(item.id)}
              className={`
                flex items-center w-full px-4 py-3 text-gray-700 rounded-lg 
                transition-colors hover:bg-gray-100
                ${currentSection === item.id ? 'bg-red-50 text-[#c62222]' : ''}
              `}
            >
              <item.icon 
                className={`h-5 w-5 ml-3 ${
                  currentSection === item.id ? 'text-[#c62222]' : 'text-gray-500'
                }`} 
              />
              <span className="font-medium">{item.label}</span>
            </button>
          ))}
        </nav>
      </aside>
    </>
  );
}