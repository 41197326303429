import React, { useState } from 'react';
import { Users, Sliders, Bell, Database, Globe, Shield, FileText } from 'lucide-react';
import UserManagement from './UserManagement/UserManagement';
import ProjectSettings from './ProjectSettings/ProjectSettings';
import ReportSettings from './ReportSettings/ReportSettings';
import NotificationSettings from './NotificationSettings/NotificationSettings';
import BackupSettings from './BackupSettings/BackupSettings';
import LocalizationSettings from './LocalizationSettings/LocalizationSettings';
import SecuritySettings from './SecuritySettings/SecuritySettings';
import SystemLogs from './SystemLogs/SystemLogs';

type SettingsSection = 'users' | 'projects' | 'reports' | 'notifications' | 'backup' | 'localization' | 'security' | 'logs';

const settingsSections = [
  { id: 'users', label: 'إدارة المستخدمين', icon: Users, disabled: false },
  // { id: 'projects', label: 'إعدادات المشاريع', icon: Sliders, disabled: false },
  // { id: 'reports', label: 'إعدادات التقارير', icon: FileText, disabled: false },
  // { id: 'notifications', label: 'إعدادات الإشعارات', icon: Bell, disabled: true },
  // { id: 'backup', label: 'النسخ الاحتياطي والتصدير', icon: Database, disabled: true },
  // { id: 'localization', label: 'اللغة والتوطين', icon: Globe, disabled: false },
  // { id: 'security', label: 'الأمان', icon: Shield, disabled: false }
] as const;

export default function Settings() {
  const [currentSection, setCurrentSection] = useState<SettingsSection>('users');

  const renderSection = () => {
    switch (currentSection) {
      case 'users':
        return <UserManagement />;
      case 'projects':
        return <ProjectSettings />;
      case 'reports':
        return <ReportSettings />;
      case 'notifications':
        return <NotificationSettings />;
      case 'backup':
        return <BackupSettings />;
      case 'localization':
        return <LocalizationSettings />;
      case 'security':
        return <SecuritySettings />;
      case 'logs':
        return <SystemLogs />;
      default:
        return null;
    }
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">الإعدادات</h2>
      
      <div className="flex gap-6">
        <div className="w-64 bg-white rounded-lg shadow-sm p-4">
          <nav className="space-y-1">
            {settingsSections.map(section => (
              <button
                key={section.id}
                onClick={() => setCurrentSection(section.id as SettingsSection)}
                disabled={section.disabled}
                className={`w-full flex items-center px-4 py-3 text-right rounded-lg transition-colors ${
                  currentSection === section.id
                    ? 'bg-[#c62222] text-white'
                    : 'text-gray-700 hover:bg-gray-50'
                } 
                ${
                  section.disabled
                    ? 'text-gray-200'
                    : ''
                }`}
              >
                <section.icon className={`h-5 w-5 ml-3 ${
                  currentSection === section.id ? 'text-[#c62222]' : 'text-gray-400'
                }`} />
                <span className="font-medium">{section.label}</span>
              </button>
            ))}
          </nav>
        </div>

        <div className="flex-1">
          <div className="bg-white rounded-lg shadow-sm">
            {renderSection()}
          </div>
        </div>
      </div>
    </div>
  );
}