import React, { useState } from 'react';
import { ShieldCheck } from 'lucide-react';

export default function TwoFactorSettings() {
  const [isEnabled, setIsEnabled] = useState(false);

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-4">
        <div>
          <h4 className="text-lg font-medium text-gray-900">المصادقة الثنائية</h4>
          <p className="text-sm text-gray-500 mt-1">قم بتفعيل المصادقة الثنائية لتعزيز أمان حسابك</p>
        </div>
        <ShieldCheck className="h-5 w-5 text-gray-400" />
      </div>

      <div className="flex items-center justify-between">
        <div className="flex-1">
          <p className="text-sm text-gray-600">
            {isEnabled
              ? 'المصادقة الثنائية مفعلة. سيتم طلب رمز إضافي عند تسجيل الدخول.'
              : 'قم بتفعيل المصادقة الثنائية للحصول على حماية إضافية.'}
          </p>
        </div>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={isEnabled}
            onChange={() => setIsEnabled(!isEnabled)}
          />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:right-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#c62222]"></div>
        </label>
      </div>
    </div>
  );
}