import React, { useState, useEffect } from 'react';
import ProjectSelector from './ProjectSelector';
import DailyProductionForm from './DailyProductionForm';
import { useTranslation } from 'react-i18next';
import { useProjectsStore } from '../../store/projects';
import { useDailyProductionStore } from '../../store/dailyProduction';
import { useAuthStore } from '../../store/auth';


export default function DailyProduction() {
  const [selectedProjectId, setSelectedProjectId] = useState<string>('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const { t } = useTranslation();
  const { projects } = useProjectsStore();
  const { addEntries, getEntriesByDate, loading } = useDailyProductionStore();
  const { hasPermission } = useAuthStore();


  const selectedProject = projects.find(p => p.projectId === selectedProjectId);

  useEffect(() => {
    if (selectedProjectId && date) {
      getEntriesByDate(selectedProjectId, date);
    }
  }, [selectedProjectId, date, getEntriesByDate]);

  const handleProductionSave = async (entries: { itemId: string; quantity: number }[]) => {
    if (!selectedProject) return;

    try {
      await addEntries(selectedProjectId, entries, date);
    } catch (error) {
      console.error('Failed to save production:', error);
    }
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">{t('common.dailyProduction')}</h2>
      
      <div className="space-y-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <ProjectSelector
            projects={projects.filter(p => p.status === 'نشط')}
            selectedProjectId={selectedProjectId}
            onProjectSelect={setSelectedProjectId}
            date={date}
            onDateChange={setDate}
          />
        </div>

        {selectedProject && (
          <DailyProductionForm
            project={selectedProject}
            date={date}
            onSave={handleProductionSave}
            readOnly={!hasPermission('add_production')}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
}