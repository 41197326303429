import React from 'react';
import { History } from 'lucide-react';

interface Session {
  id: string;
  device: string;
  location: string;
  lastActive: string;
  isCurrent: boolean;
}

const activeSessions: Session[] = [
  {
    id: '1',
    device: 'Chrome على Windows',
    location: 'الرياض، المملكة العربية السعودية',
    lastActive: 'الآن',
    isCurrent: true
  },
  {
    id: '2',
    device: 'Safari على iPhone',
    location: 'جدة، المملكة العربية السعودية',
    lastActive: 'منذ 2 ساعة',
    isCurrent: false
  }
];

export default function SessionSettings() {
  const handleEndSession = (sessionId: string) => {
    // Handle ending session logic
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-4">
        <div>
          <h4 className="text-lg font-medium text-gray-900">جلسات تسجيل الدخول النشطة</h4>
          <p className="text-sm text-gray-500 mt-1">إدارة جلسات تسجيل الدخول على جميع الأجهزة</p>
        </div>
        <History className="h-5 w-5 text-gray-400" />
      </div>

      <div className="space-y-4">
        {activeSessions.map((session) => (
          <div
            key={session.id}
            className="flex items-center justify-between p-4 border rounded-lg"
          >
            <div>
              <p className="font-medium text-gray-900">
                {session.device}
                {session.isCurrent && (
                  <span className="mr-2 px-2 py-1 text-xs bg-green-100 text-green-800 rounded-full">
                    الجلسة الحالية
                  </span>
                )}
              </p>
              <p className="text-sm text-gray-500 mt-1">
                {session.location} • آخر نشاط: {session.lastActive}
              </p>
            </div>
            {!session.isCurrent && (
              <button
                onClick={() => handleEndSession(session.id)}
                className="px-3 py-1 text-sm text-red-600 hover:text-red-700"
              >
                إنهاء الجلسة
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}