  import React, { useState, useRef, useEffect } from 'react';
  import Sidebar from './components/Sidebar';
  import ProjectsList from './components/Projects/ProjectsList';
  import Reports from './components/Reports/Reports';
  import Dashboard from './components/Dashboard';
  import Timeline from './components/Timeline/Timeline';
  import DailyProduction from './components/DailyProduction/DailyProduction';
  import Settings from './components/Settings/Settings';
  import ErrorBoundary from './components/ErrorBoundary';
  import Header from './components/Header/Header';
  import Footer from './components/Footer';
  import Login from './components/Login/Login';
  import { Section } from './types';
  import { useAuthStore } from './store/auth';
  import { useNotificationsStore } from './store/notifications';
  import { useProjectsStore } from './store/projects';

export default function App() {
  const [currentSection, setCurrentSection] = useState<Section>('dashboard');
  const { isAuthenticated, user, logout } = useAuthStore();
  const { notifications, markAsRead } = useNotificationsStore();
  const { loading, error } = useProjectsStore(state => ({
    loading: state.loading,
    error: state.error
  }));

  useEffect(() => {
    if (isAuthenticated) {
      useProjectsStore.getState().loadProjects();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated || !user) {
    return <Login />;
  }

  const handleSettingsClick = () => {
    setCurrentSection('settings');
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center h-full">
          <div className="flex flex-col items-center space-y-4">
            <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
            <p className="text-gray-600">جاري تحميل البيانات...</p>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex items-center justify-center h-full">
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
            <p>حدث خطأ أثناء تحميل البيانات</p>
            <p className="text-sm">{error}</p>
          </div>
        </div>
      );
    }

    return renderSection();
  };

  const renderSection = () => {
    switch (currentSection) {
      case 'dashboard':
        return <Dashboard />;
      case 'projects':
        return <ProjectsList />;
      case 'reports':
        return <Reports />;
      case 'timeline':
        return <Timeline />;
      case 'production':
        return <DailyProduction />;
      case 'settings':
        return <Settings />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-50">
      <Sidebar currentSection={currentSection} onSectionChange={setCurrentSection} />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header
          userName={user.name}
          userRole={user.role}
          notifications={notifications}
          onLogout={logout}
          onSettingsClick={handleSettingsClick}
          onNotificationRead={markAsRead}
        />
        <main className="flex-1 overflow-y-auto">
          <ErrorBoundary>
            {renderContent()}
          </ErrorBoundary>
        </main>
        <Footer />
      </div>
    </div>
  );
}