// Reports.tsx
import React, { useState, useEffect } from 'react';
import { FileSpreadsheet, FileText } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../store/auth';
import { useProjectsStore } from '../../store/projects';
import { useReportsStore } from '../../store/reports';
import { ReportType, ReportPeriod } from '../../types';
import ReportFilters from './ReportFilters';
import ProductionReport from './ProductionReport';
import FinancialReport from './FinancialReport';
import TimelineReport from './TimelineReport';
import { exportToPDF, exportToExcel } from '../../utils/exportUtils';

export default function Reports() {
  const [reportType, setReportType] = useState<ReportType>('production');
  const [period, setPeriod] = useState<ReportPeriod>('daily');
  const [selectedProjectId, setSelectedProjectId] = useState<string>('');
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0]
  });

  const { t } = useTranslation();
  const { hasPermission } = useAuthStore();
  const { projects } = useProjectsStore();
  const { productionReport, financialReport, timelineReport, getProductionReport, getFinancialReport, getTimelineReport } = useReportsStore();

  // Reset selected project when switching report types
  const handleReportTypeChange = (type: ReportType) => {
    setSelectedProjectId(''); // Reset selected project
    setReportType(type);
  };

  // Load report data when filters change
  useEffect(() => {
    if (!selectedProjectId) return;

    const loadReport = async () => {
      try {
        switch (reportType) {
          case 'production':
            await getProductionReport(selectedProjectId, period, dateRange);
            break;
          case 'financial':
            await getFinancialReport(selectedProjectId, period, dateRange);
            break;
          case 'timeline':
            await getTimelineReport(selectedProjectId, period);
            break;
        }
      } catch (error) {
        console.error('Error loading report:', error);
        setSelectedProjectId(''); // Reset project selection on error
      }
    };

    loadReport();
  }, [selectedProjectId, reportType, period, dateRange.startDate, dateRange.endDate]);

  const handleExportExcel = async () => {
    const report = getReportByType();
    if (!report) return;
  
    const reportTitle = getReportTitle(reportType);
    exportToExcel(report, dateRange, {
      title: reportTitle,
      subtitle: report.projectName,
      showDate: true
    });
  };
  
  const handleExportPDF = async () => {
    const report = getReportByType();
    if (!report) return;
  
    const reportTitle = getReportTitle(reportType);
    await exportToPDF(report, dateRange, {
      title: reportTitle,
      subtitle: report.projectName,
      showDate: true,
      orientation: reportType === 'timeline' ? 'landscape' : 'portrait'
    });
  };

  const getReportByType = () => {
    switch (reportType) {
      case 'production':
        return productionReport;
      case 'financial':
        return financialReport;
      case 'timeline':
        return timelineReport;
      default:
        return null;
    }
  };

  const getReportTitle = (type: ReportType): string => {
    switch (type) {
      case 'production':
        return t('reports.productionReport');
      case 'financial':
        return t('reports.financialReport');
      case 'timeline':
        return t('reports.timelineReport');
      default:
        return '';
    }
  };

  const renderReport = () => {
    if (!selectedProjectId) {
      return (
        <div className="bg-white rounded-lg shadow-sm p-8 text-center">
          <p className="text-gray-600">{t('reports.selectProject')}</p>
        </div>
      );
    }

    switch (reportType) {
      case 'production':
        if (hasPermission('view_production_reports')) {
          return (
            <ProductionReport
              projectId={selectedProjectId}
              period={period}
              dateRange={dateRange}
            />
          );
        }

      case 'financial':
        if (hasPermission('view_financial_reports')) {
          return (
            <FinancialReport
              projectId={selectedProjectId}
              period={period}
              dateRange={dateRange}
            />
          );
        }

      case 'timeline':
        if (hasPermission('view_time_reports')) {
          return (
            <TimelineReport
              projectId={selectedProjectId}
              period={period}
              dateRange={dateRange}
            />
          );
        }

      default:
        return null;
    }
  };

  return (
    <div className="p-8">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">{t('common.reports')}</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          {['production', 'financial', 'timeline'].map((type) => (
            <button
              key={type}
              onClick={() => handleReportTypeChange(type as ReportType)}
              className={`flex items-center justify-center p-4 rounded-lg border transition-colors ${
                reportType === type
                  ? 'bg-[#c62222] border-[#c62222] text-white'
                  : 'bg-white border-gray-200 text-gray-600 hover:bg-gray-50'
              }`}
            >
              {t(`reports.${type}ReportButton`)}
            </button>
          ))}
        </div>

        <div className="space-y-4">


          <ReportFilters
            period={period}
            onPeriodChange={setPeriod}
            dateRange={dateRange}
            onDateRangeChange={setDateRange}
            selectedProjectId={selectedProjectId}
            onProjectSelect={setSelectedProjectId}
          />
        </div>
      </div>

      {renderReport()}

      {selectedProjectId && hasPermission('generate_reports') && getReportByType() && (
        <div className="mt-6 flex justify-end space-x-4 space-x-reverse">
          <button
            onClick={handleExportExcel}
            className="flex items-center px-4 py-2 border border-gray-300 rounded-lg text-gray-600 hover:bg-gray-50 transition-colors"
          >
            <FileSpreadsheet className="h-5 w-5 ml-2" />
            <span>{t('reports.exportExcel')}</span>
          </button>
          <button
            onClick={handleExportPDF}
            className="flex items-center px-4 py-2 border border-gray-300 rounded-lg text-gray-600 hover:bg-gray-50 transition-colors"
          >
            <FileText className="h-5 w-5 ml-2" />
            <span>{t('reports.exportPDF')}</span>
          </button>
        </div>
      )}
    </div>
  );
}